import React from 'react';
import { Link } from 'react-router-dom';
import { useRecentSellers } from '../hooks/useRecentSellers';
import { Loader } from 'lucide-react';

const RecentSellers = () => {
  const { sellers, loading, error } = useRecentSellers();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-4">
        {error}
      </div>
    );
  }

  if (sellers.length === 0) {
    return null;
  }

  return (
    <div className="my-12">
      <h2 className="text-2xl font-bold text-secondary mb-6">
        Últimos Vendedores Registados
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
        {sellers.map((seller) => (
          <Link
            key={seller.id}
            to={`/seller/${seller.id}`}
            className="group"
          >
            <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center transition-all duration-200 hover:shadow-lg">
              <div className="w-20 h-20 rounded-full overflow-hidden mb-3">
                <img
                  src={seller.logoBase64}
                  alt={seller.commercialName}
                  className="w-full h-full object-cover transition-transform duration-200 group-hover:scale-110"
                />
              </div>
              <h3 className="text-secondary font-medium text-center text-sm">
                {seller.commercialName}
              </h3>
              <p className="text-gray-500 text-xs text-center mt-1">
                {seller.district}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RecentSellers;