import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X } from 'lucide-react';
import { useSearch } from '../hooks/useSearch';
import { Product } from '../types';

const SearchBar = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { results, loading } = useSearch(query);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleProductClick = (product: Product) => {
    navigate(`/product/${product.id}`);
    setIsOpen(false);
    setQuery('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setIsOpen(value.length > 0);
  };

  return (
    <div ref={searchRef} className="relative flex-1 max-w-2xl">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Pesquisar produtos..."
          className="w-full px-4 py-2 rounded-lg border border-secondary-light bg-white/10 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
        />
        {query && (
          <button
            onClick={() => {
              setQuery('');
              setIsOpen(false);
            }}
            className="absolute right-10 top-2.5 text-gray-300 hover:text-white"
          >
            <X size={20} />
          </button>
        )}
        <Search className="absolute right-3 top-2.5 text-gray-300" size={20} />
      </div>

      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg max-h-96 overflow-y-auto">
          {loading ? (
            <div className="p-4 text-center text-gray-500">A pesquisar...</div>
          ) : results.length > 0 ? (
            <div className="py-2">
              {results.map((product) => (
                <button
                  key={product.id}
                  onClick={() => handleProductClick(product)}
                  className="w-full px-4 py-2 hover:bg-primary/10 flex items-center space-x-3 text-left"
                >
                  <img
                    src={product.mainImage?.includes('storage.googleapis.com')
                      ? product.mainImage
                      : product.mainImage || 'https://plataformaconcreta.pt/logos/lognow/default-product.png'}
                    alt={product.name}
                    className="w-12 h-12 object-cover rounded"
                    loading="lazy"
                  />
                  <div className="flex-1">
                    <div className="font-medium text-secondary">{product.name}</div>
                    <div className="text-sm text-gray-500">
                      €{(product.salePrice || product.regularPrice).toFixed(2)}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ) : query.length > 0 ? (
            <div className="p-4 text-center text-gray-500">
              Nenhum produto encontrado
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SearchBar;