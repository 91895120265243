import React, { createContext, useContext, useState, useEffect } from 'react';
import { User as FirebaseUser } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';

interface AuthContextType {
  user: FirebaseUser | null;
  loading: boolean;
  userRole: string | null;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  userRole: null,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Buscar dados do usuário no Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (!userDoc.exists()) {
            console.error('User document not found');
            await auth.signOut();
            setUser(null);
            setUserRole(null);
            return;
          }

          const userData = userDoc.data();
          
          // Verificar explicitamente o role
          if (userData.role) {
            const userRole = userData.role.toLowerCase();
            console.log('Auth state change - User role:', userRole); // Debug log
            
            if (userRole === 'gerente' || userRole === 'funcionario') {
              console.log('Auth state change - Blocking vendor access'); // Debug log
              await auth.signOut();
              setUser(null);
              setUserRole(null);
              return;
            }
            
            setUserRole(userRole);
          } else {
            console.error('User role not found');
            await auth.signOut();
            setUser(null);
            setUserRole(null);
            return;
          }

          setUser(user);
        } catch (error) {
          console.error('Error fetching user data:', error);
          await auth.signOut();
          setUser(null);
          setUserRole(null);
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, userRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};