import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';

const Sitemap = () => {
  useEffect(() => {
    const generateSitemap = async () => {
      try {
        // Busca todos os produtos
        const productsRef = collection(db, 'products');
        const snapshot = await getDocs(productsRef);
        const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Product));

        // Lista de URLs estáticas
        const staticUrls = [
          '',
          '/categories',
          '/favorites',
          '/track-order',
          '/terms',
          '/privacy',
          '/complaints',
          '/dispute'
        ];

        const today = new Date().toISOString().split('T')[0];

        const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${staticUrls.map(url => `
  <url>
    <loc>https://emtuacasa.pt${url}</loc>
    <lastmod>${today}</lastmod>
    <changefreq>weekly</changefreq>
    <priority>${url === '' ? '1.0' : '0.8'}</priority>
  </url>`).join('')}
  
  ${products.map(product => `
  <url>
    <loc>https://emtuacasa.pt/product/${product.id}</loc>
    <lastmod>${today}</lastmod>
    <changefreq>daily</changefreq>
    <priority>0.9</priority>
  </url>`).join('')}
</urlset>`;

        // Define o tipo de conteúdo como XML
        const blob = new Blob([xmlContent], { type: 'application/xml' });
        const url = window.URL.createObjectURL(blob);
        window.location.href = url;

      } catch (error) {
        console.error('Erro ao gerar sitemap:', error);
      }
    };

    generateSitemap();
  }, []);

  return null;
};

export default Sitemap;
