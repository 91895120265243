import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  url: string;
  type?: string;
  price?: string;
  brand?: string;
  availability?: boolean;
  productId?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image = 'https://plataformaconcreta.pt/logos/lognow/og-image.png',
  url,
  type = 'website',
  price,
  brand = 'EM TUA CASA®',
  availability,
  productId
}) => {
  const isProduct = type === 'product';
  const absoluteImageUrl = image.startsWith('http') ? image : `${window.location.origin}${image}`;
  
  // Formata o título e descrição no estilo da Worten
  const formattedTitle = isProduct ? `${title} | EM TUA CASA®` : title;
  const formattedDescription = isProduct 
    ? `Sabe mais sobre ${title}. Descobre todas as características e compra online em EM TUA CASA®.`
    : description;

  return (
    <Helmet>
      {/* Título e descrição básicos */}
      <title>{formattedTitle}</title>
      <meta name="description" content={formattedDescription} />

      {/* Tags Open Graph básicas */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={formattedDescription} />
      <meta property="og:image" content={absoluteImageUrl} />
      <meta property="og:image:type" content={image.endsWith('.png') ? 'image/png' : 'image/jpeg'} />
      <meta property="og:site_name" content="EM TUA CASA®" />
      <meta property="og:locale" content="pt_PT" />

      {/* Tags específicas para produtos */}
      {isProduct && (
        <>
          <meta property="product:price:amount" content={price} />
          <meta property="product:price:currency" content="EUR" />
          <meta property="product:brand" content={brand} />
          <meta property="product:availability" content={availability ? 'in stock' : 'out of stock'} />
          <meta property="product:retailer_item_id" content={productId} />
          <meta property="product:is_product_shareable" content="1" />
        </>
      )}

      {/* Tags Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={formattedTitle} />
      <meta name="twitter:description" content={formattedDescription} />
      <meta name="twitter:image" content={absoluteImageUrl} />
    </Helmet>
  );
};

export default SEO;
