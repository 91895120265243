import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';
import { useCategories } from './useCategories';

export const useProductsByCategory = (categorySlug: string | undefined) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { categories } = useCategories();
  const category = categories.find(c => c.slug === categorySlug);

  useEffect(() => {
    const fetchProducts = async () => {
      if (!categorySlug || !category) {
        setLoading(false);
        return;
      }

      try {
        const productsRef = collection(db, 'products');
        const q = query(
          productsRef,
          where('category', '==', category.name),
          where('isPrivateSale', '==', true)
        );
        
        const snapshot = await getDocs(q);
        const productsData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          // Filtra apenas produtos com estoque
          .filter(product => product.stockQuantity > 0)
          .sort((a, b) => 
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ) as Product[];

        setProducts(productsData);
      } catch (err) {
        console.error('Error fetching category products:', err);
        setError('Erro ao carregar produtos da categoria');
      } finally {
        setLoading(false);
      }
    };

    if (categorySlug && category) {
      fetchProducts();
    }
  }, [categorySlug, category]);

  return { products, category, loading, error };
};