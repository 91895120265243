import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db, handleFirebaseError } from '../lib/firebase';
import { Product } from '../types';

const normalizeText = (text: string) => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
};

export const useSearch = (searchQuery: string) => {
  const [results, setResults] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const searchProducts = async () => {
      if (!searchQuery.trim()) {
        setResults([]);
        return;
      }

      setLoading(true);
      try {
        const productsRef = collection(db, 'products');
        const q = query(
          productsRef,
          where('isPrivateSale', '==', true),
          limit(20)
        );
        
        const snapshot = await getDocs(q);
        const allProducts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Product[];

        const normalizedQuery = normalizeText(searchQuery);
        
        // Filter products that match the search query
        const filteredProducts = allProducts.filter(product => {
          const normalizedName = normalizeText(product.name);
          const normalizedSku = normalizeText(product.sku);
          const normalizedEan = product.ean ? normalizeText(product.ean) : '';
          
          return (
            normalizedName.includes(normalizedQuery) ||
            normalizedSku.includes(normalizedQuery) ||
            normalizedEan.includes(normalizedQuery)
          );
        });

        // Sort by relevance
        const sortedProducts = filteredProducts.sort((a, b) => {
          const nameA = normalizeText(a.name);
          const nameB = normalizeText(b.name);
          
          // Exact matches first
          const exactMatchA = nameA === normalizedQuery;
          const exactMatchB = nameB === normalizedQuery;
          if (exactMatchA && !exactMatchB) return -1;
          if (!exactMatchA && exactMatchB) return 1;
          
          // Starts with matches second
          const startsWithA = nameA.startsWith(normalizedQuery);
          const startsWithB = nameB.startsWith(normalizedQuery);
          if (startsWithA && !startsWithB) return -1;
          if (!startsWithA && startsWithB) return 1;
          
          // Alphabetical order for remaining matches
          return nameA.localeCompare(nameB);
        });

        setResults(sortedProducts.slice(0, 5));
      } catch (err) {
        console.error('Search error:', err);
        setError(handleFirebaseError(err));
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    const debounceTimeout = setTimeout(searchProducts, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchQuery]);

  return { results, loading, error };
};