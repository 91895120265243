import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useProductsByCategory } from '../hooks/useProductsByCategory';
import ProductCard from '../components/ProductCard';
import { Loader, ChevronLeft } from 'lucide-react';

const CategoryProductsPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const { products, category, loading, error } = useProductsByCategory(slug);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center text-red-500">
          {error}
        </div>
      </div>
    );
  }

  if (!category) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center text-red-500">
          Categoria não encontrada
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="flex items-center space-x-4 mb-8">
        <Link
          to="/categories"
          className="flex items-center text-primary hover:text-primary-dark"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Voltar às Categorias</span>
        </Link>
        <h1 className="text-3xl font-bold text-secondary">{category.name}</h1>
      </div>

      {products.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500 py-12">
          Nenhum produto encontrado nesta categoria
        </div>
      )}
    </div>
  );
};

export default CategoryProductsPage;