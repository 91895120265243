import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">Política de Privacidade</h1>
      
      <div className="prose max-w-none">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">1. IDENTIFICAÇÃO DO RESPONSÁVEL PELO TRATAMENTO</h2>
          <p>
            <strong>Empresa:</strong> Plataforma Concreta, Unipessoal Lda (adiante "EM TUA CASA®")<br />
            <strong>Sede:</strong> Rua da Fonte, N.º 66, 4485-702 Vila Chã, Vila do Conde, Portugal<br />
            <strong>NIPC:</strong> 515240834<br />
            <strong>Contacto Encarregado da Proteção de Dados:</strong> ola@emtuacasa.pt
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">2. INFORMAÇÃO E CONSENTIMENTO</h2>
          <p>
            A Lei da Proteção de Dados Pessoais (em diante "LPDP") e o Regulamento Geral de Proteção de Dados (Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, em diante "RGPD") asseguram a proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados.
          </p>
          <p>
            Nos termos legais, são considerados "dados pessoais" qualquer informação, de qualquer natureza e independentemente do respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou identificável, pelo que a proteção não abrange os dados de pessoas coletivas.
          </p>
          <p>
            Em caso algum será solicitada informação sobre convicções filosóficas ou políticas, filiação partidária ou sindical, fé religiosa, vida privada e origem racial ou étnica bem como os dados relativos à saúde e à vida sexual, incluindo os dados genéticos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">3. FINALIDADES DO TRATAMENTO DE DADOS PESSOAIS</h2>
          <p>Os dados pessoais que tratamos através desta página serão unicamente utilizados para as seguintes finalidades:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Execução e gestão de contrato de compra e venda ou de prestação de serviços;</li>
            <li>Processamento de encomendas;</li>
            <li>Comunicação com clientes e esclarecimento de dúvidas;</li>
            <li>Processamento de pedidos de informação;</li>
            <li>Processamento de reclamações;</li>
            <li>Atividades de análise estatística;</li>
            <li>Verificar, manter e desenvolver sistemas;</li>
            <li>Comunicações de marketing direto (caso tenha consentido);</li>
            <li>Prevenção e combate à fraude;</li>
            <li>Solicitação de comentários a produtos;</li>
            <li>Realização de inquéritos de satisfação;</li>
            <li>Gestão e participação em eventos, passatempos e campanhas.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">4. CESSÃO DE DADOS PESSOAIS</h2>
          <p>
            Por forma a poder cumprir com o objeto do presente website, a EM TUA CASA® poderá ceder os seus dados pessoais a outras entidades, que os irão tratar, para as seguintes finalidades:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Atividades de gestão e processamento de pagamentos;</li>
            <li>Prestação dos serviços contratados;</li>
            <li>Gestão e participação em eventos, passatempos e campanhas.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">5. MEDIDAS DE SEGURANÇA</h2>
          <p>
            A EM TUA CASA® declara que implementou e continuará a implementar as medidas de segurança de natureza técnica e organizativa necessárias para garantir a segurança dos dados de caráter pessoal que lhe sejam fornecidos, visando evitar a sua alteração, perda, tratamento e/ou acesso não autorizado.
          </p>
          <p>
            A EM TUA CASA® garante a confidencialidade de todos os dados fornecidos pelos seus clientes. Não obstante a EM TUA CASA® proceder à recolha e ao tratamento de dados de forma segura e que impede a sua perda ou manipulação, utilizando as técnicas mais avançadas para o efeito.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">6. COMUNICAÇÕES COMERCIAIS E PROMOCIONAIS</h2>
          <p>
            Um dos propósitos para os quais tratamos dados pessoais fornecidos pelos utilizadores é enviar comunicações eletrónicas com informações relativas a comunicações comerciais e promocionais.
          </p>
          <p>
            Sempre que fizermos uma comunicação deste tipo, será direcionado exclusivamente aos utilizadores que as tenham autorizado previamente e de forma expressa.
          </p>
          <p>
            Caso deseje deixar de receber comunicações comerciais ou promocionais da EM TUA CASA®, poderá solicitar a oposição do serviço enviando um e-mail para ola@emtuacasa.pt.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">7. EXERCÍCIO DOS DIREITOS</h2>
          <p>
            De acordo com as disposições da LDPD e do RGPD, o utilizador pode exercer a qualquer momento os seus direitos de acesso, retificação, apagamento, limitação, oposição e portabilidade, através de solicitação por qualquer dos seguintes meios:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Correio: Rua da Fonte, N.º 66, 4485-702 Vila Chã, Vila do Conde, Portugal</li>
            <li>E-mail: ola@emtuacasa.pt</li>
            <li>Telefone: 220941891 (chamada para rede fixa nacional) das 10h às 20h de segunda a domingo</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-secondary mb-4">8. AUTORIDADE DE CONTROLO</h2>
          <p>
            Nos termos legais, o titular dos dados tem o direito de apresentar uma reclamação em matéria de proteção de dados pessoais à autoridade de controlo competente, a Comissão Nacional de Proteção de Dados (CNPD).
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPage;