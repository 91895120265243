import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useShippingCosts } from '../hooks/useShippingCosts';
import { useShippingAddresses } from '../hooks/useShippingAddresses';

interface AddressFormProps {
  onSuccess: (address: any) => void;
  onCancel: () => void;
  existingAddress?: any;
  hideCancel?: boolean;
}

const AddressForm: React.FC<AddressFormProps> = ({
  onSuccess,
  onCancel,
  existingAddress,
  hideCancel = false
}) => {
  const { user } = useAuth();
  const { costs } = useShippingCosts();
  const { addAddress, updateAddress } = useShippingAddresses(user?.uid);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: existingAddress?.name || '',
    address: existingAddress?.address || '',
    city: existingAddress?.city || '',
    district: existingAddress?.district || '',
    postalCode: existingAddress?.postalCode || '',
    email: existingAddress?.email || '',
    phone: existingAddress?.phone || '',
    taxNumber: existingAddress?.taxNumber || '',
    isDefault: existingAddress?.isDefault || false
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const addressData = {
        ...formData,
        userId: user?.uid || 'guest',
        createdAt: existingAddress?.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      let savedAddress;
      if (user) {
        if (existingAddress?.id) {
          await updateAddress(existingAddress.id, addressData);
          savedAddress = { id: existingAddress.id, ...addressData };
        } else {
          savedAddress = await addAddress(addressData);
        }
      } else {
        savedAddress = { id: `guest-${Date.now()}`, ...addressData };
      }

      onSuccess(savedAddress);
    } catch (err) {
      console.error('Erro ao guardar morada:', err);
      setError('Erro ao guardar morada. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nome</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Telefone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">NIF (opcional)</label>
          <input
            type="text"
            name="taxNumber"
            value={formData.taxNumber}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700">Morada</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Código Postal</label>
          <input
            type="text"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Cidade</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Distrito</label>
          <select
            name="district"
            value={formData.district}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          >
            <option value="">Selecione o distrito</option>
            {Object.entries(costs).map(([district, cost]) => (
              <option key={district} value={district}>
                {district} (€{cost.toFixed(2)})
              </option>
            ))}
          </select>
        </div>

        {user && (
          <div className="md:col-span-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="isDefault"
                checked={formData.isDefault}
                onChange={handleChange}
                className="rounded border-gray-300 text-primary focus:ring-primary"
              />
              <span className="text-sm text-gray-700">Definir como morada predefinida</span>
            </label>
          </div>
        )}
      </div>

      {error && (
        <div className="text-red-500 text-sm">{error}</div>
      )}

      <div className="flex justify-end space-x-3">
        {!hideCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Cancelar
          </button>
        )}
        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 bg-primary text-secondary rounded-md hover:bg-primary-dark disabled:opacity-50"
        >
          {loading ? 'A guardar...' : 'Guardar Morada'}
        </button>
      </div>
    </form>
  );
};

export default AddressForm;