import React from 'react';
import TermsSection from '../TermsSection';

const ChapterOne = () => {
  return (
    <>
      <TermsSection title="Introdução">
        <p>
          O presente documento estabelece a informação relativa à conta online e aos contratos celebrados à distância na página web EM TUA CASA®, e de todos os seus subdomínios, a que alude o Decreto-Lei nº 24/2014 de 14 de fevereiro na redação atual, quer no âmbito da compra e venda dos bens e serviços estabelecida sem criação de conta, quer no âmbito da conta EM TUA CASA®.
        </p>
        <p>
          A EM TUA CASA® também disponibiliza no Site EM TUA CASA®, a possibilidade de os Clientes comprarem produtos diretamente a terceiros (doravante "Vendedores") através do "Marketplace EM TUA CASA®", cujos termos e condições se encontram previstos no capítulo III infra.
        </p>
        <p>
          O Site EM TUA CASA® é propriedade da Plataforma Concreta, Unipessoal Lda, com sede na Rua da Fonte N 66 Vila Chã; 4485-702 Vila Chã, pessoa coletiva n° 515240834, que é também o seu número de matrícula na Conservatória do Registo Comercial do Porto, de ora em diante abreviadamente designada por EM TUA CASA®.
        </p>
      </TermsSection>

      <TermsSection title="2. Condições de Utilização do Site EM TUA CASA®">
        <p>
          O Utilizador deve ter a idade mínima de 18 (dezoito) anos para criar conta online e efetuar compras no Site EM TUA CASA®.
        </p>
        <p>
          O Utilizador deve utilizar o Site EM TUA CASA® em cumprimento com o disposto na legislação aplicável.
        </p>
      </TermsSection>

      <TermsSection title="2.1 Moderação de conteúdos">
        <p>
          A EM TUA CASA® repudia qualquer tipo de informações, produtos, serviços ou atividades cujos conteúdos, independentemente da sua forma, atentem contra as leis do direito nacional ou da União Europeia, regulamentos ou princípios, valores, regras e procedimentos da EM TUA CASA® ou que se relacionem com atividades ilegais.
        </p>
      </TermsSection>

      {/* Add all other sections from Chapter I */}
    </>
  );
};

export default ChapterOne;