import { useState, useEffect } from 'react';
import { getMultibancoPayment } from '../lib/firebase';
import type { MultibancoPayment } from '../types';

export const useMultibancoPayment = (orderId: string | undefined) => {
  const [payment, setPayment] = useState<MultibancoPayment | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPayment = async () => {
      if (!orderId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const paymentData = await getMultibancoPayment(orderId);
        setPayment(paymentData);
      } catch (err: any) {
        console.error('Erro ao buscar pagamento:', err);
        setError(err.message || 'Erro ao carregar dados do pagamento');
      } finally {
        setLoading(false);
      }
    };

    fetchPayment();
  }, [orderId]);

  return { payment, loading, error };
};