import React from 'react';
import { FileText, Download, Clock } from 'lucide-react';
import { useWalletTransaction } from '../hooks/useWalletTransaction';

interface InvoiceSectionProps {
  orderId: string;
}

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ orderId }) => {
  const { transaction, loading } = useWalletTransaction(orderId);

  if (loading) {
    return (
      <div className="animate-pulse bg-gray-100 rounded-lg p-4">
        <div className="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      </div>
    );
  }

  const handleDownloadInvoice = () => {
    if (!transaction?.invoicePdf) return;

    // Create a blob from the base64 PDF data
    const byteCharacters = atob(transaction.invoicePdf.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create download link
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `fatura_${transaction.orderId}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center space-x-3 mb-4">
        <FileText className="w-6 h-6 text-primary" />
        <h2 className="text-lg font-semibold text-secondary">Fatura</h2>
      </div>

      {transaction?.invoicePdf ? (
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-600">
              Fatura disponível para download
            </p>
            <p className="text-sm text-gray-500">
              Emitida em: {new Date(transaction.updatedAt).toLocaleDateString('pt-PT')}
            </p>
          </div>
          <button
            onClick={handleDownloadInvoice}
            className="flex items-center space-x-2 bg-primary text-secondary px-4 py-2 rounded-md hover:bg-primary-dark transition-colors"
          >
            <Download className="w-4 h-4" />
            <span>Download</span>
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-3 text-gray-600">
          <Clock className="w-5 h-5" />
          <p>Aguardar a emissão da sua fatura</p>
        </div>
      )}
    </div>
  );
};

export default InvoiceSection;