import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Category } from '../types';
import { slugify } from '../utils/slugify';
import { getIconFromName } from '../utils/categoryIcons';

export const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productsRef = collection(db, 'products');
        const q = query(productsRef, where('isPrivateSale', '==', true));
        const snapshot = await getDocs(q);
        
        // Extract unique categories from products with stock
        const categoriesMap = new Map();
        snapshot.docs.forEach(doc => {
          const data = doc.data();
          // Só adiciona a categoria se o produto tiver estoque
          if (data.category && data.stockQuantity > 0 && !categoriesMap.has(data.category)) {
            categoriesMap.set(data.category, {
              id: slugify(data.category),
              name: data.category,
              slug: slugify(data.category),
              createdAt: data.createdAt || new Date().toISOString(),
              createdBy: data.createdBy || 'system',
              icon: getIconFromName(data.category)
            });
          }
        });
        
        const categoriesArray = Array.from(categoriesMap.values())
          .sort((a, b) => a.name.localeCompare(b.name));

        setCategories(categoriesArray);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError('Erro ao carregar categorias');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};