import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';

export const useRecentProducts = (limitCount = 8) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, 'products');
        const q = query(
          productsRef,
          orderBy('createdAt', 'desc'),
          limit(limitCount * 2) // Fetch more to filter
        );
        
        const snapshot = await getDocs(q);
        const productsData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(product => 
            product.isPrivateSale === true &&
            product.stockQuantity > 0 &&
            product.stockQuantity > 0
          )
          .slice(0, limitCount) as Product[];

        setProducts(productsData);
      } catch (err) {
        console.error('Error fetching recent products:', err);
        setError('Erro ao carregar produtos recentes');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [limitCount]);

  return { products, loading, error };
};