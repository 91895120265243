import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  updateProfile,
  setPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  User as FirebaseUser
} from 'firebase/auth';
import { 
  getFirestore,
  doc, 
  setDoc,
  addDoc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  Timestamp,
  writeBatch,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import type { 
  Order, 
  MultibancoPayment, 
  MBWayPayment,
  WalletTransaction,
  OrderComment 
} from '../types';
import { distributeWalletPayment } from '../utils/walletPaymentUtils';

const firebaseConfig = {
  apiKey: "AIzaSyAZBn-bz1Sk8dbK7AYnwx02Q6A76Q0Ceak",
  authDomain: "lognow-controlo.firebaseapp.com",
  projectId: "lognow-controlo",
  storageBucket: "lognow-controlo.appspot.com",
  messagingSenderId: "41207915465",
  appId: "1:41207915465:web:8db3b0a910d67be7697e18"
};

const WEBHOOK_URL = 'https://hook.eu1.make.com/ygugybpsldbspo8zxbwwg7krmn16yc9c';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore with persistent cache
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});

const storage = getStorage(app);
const functions = getFunctions(app);

setPersistence(auth, browserLocalPersistence).catch(console.error);

const handleFirebaseError = (error: any): string => {
  console.error('Firebase error:', error);
  
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'Este email já está registado.';
    case 'auth/invalid-email':
      return 'Email inválido.';
    case 'auth/operation-not-allowed':
      return 'Operação não permitida.';
    case 'auth/weak-password':
      return 'A password deve ter pelo menos 6 caracteres.';
    case 'auth/user-disabled':
      return 'Esta conta foi desativada.';
    case 'auth/user-not-found':
      return 'Utilizador não encontrado.';
    case 'auth/wrong-password':
      return 'Password incorreta.';
    default:
      return 'Ocorreu um erro. Por favor, tente novamente.';
  }
};

const sendOrderWebhook = async (orderData: any, payment: any = null) => {
  try {
    // Buscar dados do vendedor
    const sellerPromises = orderData.items.map(async (item: any) => {
      const companyRef = doc(db, 'companies', item.companyId);
      const companyDoc = await getDoc(companyRef);
      return companyDoc.data();
    });
    
    const sellers = await Promise.all(sellerPromises);
    const mainSeller = sellers[0]; // Usamos o primeiro vendedor como principal

    // Preparar dados de pagamento
    const paymentData = {
      method: orderData.paymentMethod,
      status: orderData.status,
      total: orderData.total,
      walletAmount: orderData.walletAmount || 0,
      createdAt: new Date(orderData.createdAt).toLocaleString('pt-PT', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    };

    // Se for Multibanco ou MBWay, incluir dados específicos
    if (payment) {
      if (orderData.paymentMethod === 'multibanco') {
        paymentData.reference = payment.reference;
        paymentData.entity = payment.entity;
      } else if (orderData.paymentMethod === 'mbway') {
        paymentData.phoneNumber = payment.phoneNumber;
      }
    }

    const webhookData = {
      order: {
        buyerId: orderData.buyerId,
        itemsArray: orderData.items,
        shippingAddressCollection: {
          name: orderData.shippingAddress.name,
          firstName: orderData.shippingAddress.name.split(' ')[0],
          lastName: orderData.shippingAddress.name.split(' ').slice(1).join(' '),
          phone: orderData.shippingAddress.phone,
          address: orderData.shippingAddress.address,
          postalCode: orderData.shippingAddress.postalCode,
          city: orderData.shippingAddress.city,
          district: orderData.shippingAddress.district,
          country: 'Portugal'
        },
        shippingMethod: orderData.shippingMethod,
        shippingCost: orderData.shippingCost,
        subtotal: orderData.subtotal,
        total: orderData.total,
        orderNumber: orderData.orderNumber,
        paymentMethod: orderData.paymentMethod,
        useWallet: orderData.useWallet,
        walletAmount: orderData.walletAmount,
        isOnlineSale: orderData.isOnlineSale,
        createdBy: orderData.createdBy,
        id: orderData.id,
        status: orderData.status,
        createdAt: new Date(orderData.createdAt).toLocaleString('pt-PT', { 
          day: 'numeric', 
          month: 'long', 
          year: 'numeric', 
          hour: 'numeric', 
          minute: 'numeric' 
        }),
        updatedAt: new Date(orderData.updatedAt).toLocaleString('pt-PT', { 
          day: 'numeric', 
          month: 'long', 
          year: 'numeric', 
          hour: 'numeric', 
          minute: 'numeric' 
        }),
        commentsArray: orderData.comments || []
      },
      seller: {
        id: mainSeller.id,
        name: mainSeller.name,
        email: mainSeller.email,
        phone: mainSeller.phone,
        address: mainSeller.address,
        postalCode: mainSeller.postalCode,
        city: mainSeller.city,
        district: mainSeller.district,
        nif: mainSeller.nif,
        iban: mainSeller.iban
      },
      payment: paymentData,
      emailData: {
        to_name: orderData.shippingAddress.name,
        to_email: orderData.shippingAddress.email,
        order_number: orderData.orderNumber,
        order_total: `€${orderData.total.toFixed(2)}`,
        order_itemsArray: orderData.items.map((item: any) => ({
          name: item.name,
          quantity: item.quantity,
          price: `€${item.price.toFixed(2)}`,
          total: `€${(item.price * item.quantity).toFixed(2)}`
        })),
        shipping_address: {
          full_name: orderData.shippingAddress.name,
          first_name: orderData.shippingAddress.name.split(' ')[0],
          last_name: orderData.shippingAddress.name.split(' ').slice(1).join(' '),
          phone: orderData.shippingAddress.phone,
          address: orderData.shippingAddress.address,
          postal_code: orderData.shippingAddress.postalCode,
          city: orderData.shippingAddress.city,
          district: orderData.shippingAddress.district,
          country: 'Portugal'
        },
        shipping_cost: `€${orderData.shippingCost.toFixed(2)}`,
        subtotal: `€${orderData.subtotal.toFixed(2)}`,
        tracking_url: `https://lognow.pt/order/${orderData.id}`
      }
    };

    const response = await fetch(WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookData)
    });

    if (!response.ok) {
      throw new Error(`Webhook failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('Error sending webhook:', error);
    throw error;
  }
};

const registerUser = async (email: string, password: string, displayName: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await updateProfile(user, { displayName });

    await setDoc(doc(db, 'users', user.uid), {
      id: user.uid,
      email: user.email,
      displayName,
      role: 'cliente',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      onboardingCompleted: false,
      acceptedTerms: {
        general: false,
        marketplace: false,
        social: false,
        privacy: false
      }
    });

    await setDoc(doc(db, 'wallets', user.uid), {
      balance: 0,
      userId: user.uid,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    return user;
  } catch (error: any) {
    throw new Error(handleFirebaseError(error));
  }
};

const loginWithEmail = async (email: string, password: string) => {
  try {
    // Primeiro verificar se o email pertence a um vendedor
    const usersQuery = query(
      collection(db, 'users'),
      where('email', '==', email.toLowerCase())
    );
    
    const userSnapshot = await getDocs(usersQuery);
    if (!userSnapshot.empty) {
      const userData = userSnapshot.docs[0].data();
      if (userData.role && (userData.role === 'gerente' || userData.role === 'funcionario')) {
        throw new Error('VENDOR_ACCESS_DENIED');
      }
    }

    // Se não for vendedor, prosseguir com o login
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Verificar novamente os dados do usuário após o login
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      await auth.signOut();
      throw new Error('Usuário não encontrado');
    }

    const finalUserData = userDoc.data();
    if (finalUserData.role && (finalUserData.role === 'gerente' || finalUserData.role === 'funcionario')) {
      await auth.signOut();
      throw new Error('VENDOR_ACCESS_DENIED');
    }

    return user;
  } catch (error: any) {
    // Se já estiver autenticado, fazer logout
    if (auth.currentUser) {
      await auth.signOut();
    }

    // Se for erro específico de vendedor, retornar mensagem customizada
    if (error.message === 'VENDOR_ACCESS_DENIED') {
      throw new Error('Vendedores não podem usar o site da LOGNOW. Para efectuar compras use o "Vendas Privadas" no painel de controlo.');
    }

    throw new Error(handleFirebaseError(error));
  }
};

const sendPasswordResetEmail = async (email: string) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
  } catch (error: any) {
    throw new Error(handleFirebaseError(error));
  }
};

const createOrder = async (orderData: Partial<Order>) => {
  try {
    const orderRef = doc(collection(db, 'orders'));
    const timestamp = Timestamp.now();

    // Check if using wallet and determine payment method
    const useWallet = orderData.useWallet && orderData.walletAmount! > 0;
    const isFullWalletPayment = useWallet && orderData.walletAmount! >= orderData.total!;
    
    const paymentMethod = isFullWalletPayment ? 'wallet' : orderData.paymentMethod;
    const initialStatus = isFullWalletPayment ? 'pending' : 
      (paymentMethod === 'multibanco' || paymentMethod === 'mbway' ? 'awaiting_payment' : 'pending');

    // Get buyer's wallet
    const buyerWalletRef = doc(db, 'wallets', orderData.buyerId!);
    const buyerWalletDoc = await getDoc(buyerWalletRef);
    
    if (useWallet && !buyerWalletDoc.exists()) {
      throw new Error('Wallet não encontrada');
    }

    const currentBalance = buyerWalletDoc.exists() ? buyerWalletDoc.data().balance : 0;
    if (useWallet && currentBalance < orderData.walletAmount!) {
      throw new Error('Saldo insuficiente na wallet');
    }

    // Create batch for atomic operations
    const batch = writeBatch(db);

    // Prepare order document
    const order = {
      ...orderData,
      id: orderRef.id,
      paymentMethod,
      status: initialStatus,
      createdAt: timestamp.toDate().toISOString(),
      updatedAt: timestamp.toDate().toISOString(),
      comments: [
        {
          text: "Portes pagos pelo comprador",
          createdBy: "Sistema",
          createdAt: timestamp.toDate().toISOString(),
          id: Date.now().toString()
        }
      ]
    };

    // Create order
    batch.set(orderRef, order);

    if (useWallet) {
      // Update buyer's wallet
      const newBuyerBalance = currentBalance - orderData.walletAmount!;
      batch.update(buyerWalletRef, { 
        balance: newBuyerBalance,
        updatedAt: new Date().toISOString()
      });

      // Record buyer's wallet transaction
      const buyerTransactionRef = doc(collection(db, 'walletTransactions'));
      batch.set(buyerTransactionRef, {
        userId: orderData.buyerId,
        orderId: orderRef.id,
        amount: -orderData.walletAmount!,
        type: 'order_payment',
        description: `Pagamento da encomenda #${order.orderNumber}`,
        createdAt: new Date().toISOString(),
        status: 'completed',
        invoiceStatus: 'pending',
        invoiced: false
      });

      // Distribute payments to sellers if using wallet
      try {
        await distributeWalletPayment(order.items, order.orderNumber!);
      } catch (error) {
        console.error('Error distributing wallet payments:', error);
        throw new Error('Failed to distribute payments to sellers');
      }
    }

    // Commit all changes
    await batch.commit();

    // Generate payment references if needed
    let payment = null;
    if (paymentMethod === 'multibanco') {
      payment = await generateMultibancoPayment(order);
    } else if (paymentMethod === 'mbway' && orderData.mbwayPhone) {
      payment = await generateMBWayPayment(order, orderData.mbwayPhone);
    }

    // Send confirmation email
    try {
      await sendOrderConfirmationEmail(order);
    } catch (emailError) {
      console.error('Error sending confirmation email:', emailError);
    }

    // Send webhook with all data
    try {
      await sendOrderWebhook(order, payment);
    } catch (webhookError) {
      console.error('Error sending webhook:', webhookError);
      // Não lançamos erro aqui para não interromper o fluxo da criação do pedido
    }

    return orderRef.id;
  } catch (error: any) {
    console.error('Erro ao criar encomenda:', error);
    throw new Error(error.message || handleFirebaseError(error));
  }
};

const addOrderComment = async (orderId: string, comment: OrderComment) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Encomenda não encontrada');
    }

    const currentComments = orderDoc.data().comments || [];
    await updateDoc(orderRef, {
      comments: [...currentComments, comment],
      updatedAt: new Date().toISOString()
    });
  } catch (error: any) {
    console.error('Erro ao adicionar comentário:', error);
    throw new Error(handleFirebaseError(error));
  }
};

const generateMultibancoPayment = async (order: any): Promise<MultibancoPayment> => {
  try {
    const existingPayment = await getMultibancoPayment(order.id);
    if (existingPayment) {
      return existingPayment;
    }

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 3);
    const formattedExpiryDate = expiryDate.toISOString().split('T')[0];

    const response = await fetch('https://api.ifthenpay.com/multibanco/reference/init', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mbKey: "KFP-214895",
        orderId: order.orderNumber,
        amount: Number(order.total).toFixed(2),
        description: `Encomenda ${order.orderNumber}`,
        url: "https://www.lognow.pt",
        clientCode: order.buyerId,
        clientName: order.shippingAddress.name,
        clientEmail: order.shippingAddress.email,
        clientUsername: order.shippingAddress.name,
        clientPhone: parseInt(order.shippingAddress.phone.replace(/\D/g, '')),
        expiryDays: "3"
      })
    });

    if (!response.ok) {
      throw new Error('Erro ao gerar referência Multibanco');
    }

    const data = await response.json();
    
    if (data.Status !== "0") {
      throw new Error(data.Message || 'Erro ao gerar referência Multibanco');
    }

    const payment: MultibancoPayment = {
      orderId: order.id,
      orderNumber: order.orderNumber,
      entity: data.Entity,
      reference: data.Reference,
      amount: parseFloat(data.Amount),
      expiryDate: formattedExpiryDate,
      requestId: data.RequestId,
      status: 'pending',
      createdAt: new Date().toISOString()
    };

    const paymentsRef = collection(db, 'multibanco_payments');
    await addDoc(paymentsRef, payment);

    return payment;
  } catch (error) {
    console.error('Erro ao gerar pagamento Multibanco:', error);
    throw error;
  }
};

const getMultibancoPayment = async (orderId: string): Promise<MultibancoPayment | null> => {
  try {
    const paymentsRef = collection(db, 'multibanco_payments');
    const q = query(paymentsRef, where('orderId', '==', orderId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return null;
    }

    const payment = snapshot.docs[0];
    return { id: payment.id, ...payment.data() } as MultibancoPayment;
  } catch (error) {
    console.error('Erro ao buscar pagamento:', error);
    throw error;
  }
};

const generateMBWayPayment = async (order: any, phoneNumber: string): Promise<MBWayPayment> => {
  try {
    const response = await fetch('https://api.ifthenpay.com/spg/payment/mbway', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mbWayKey: "CKY-865055",
        orderId: order.orderNumber,
        amount: Number(order.total).toFixed(2),
        mobileNumber: phoneNumber,
        description: `Encomenda ${order.orderNumber}`,
        email: order.shippingAddress.email
      })
    });

    if (!response.ok) {
      throw new Error('Erro ao gerar pagamento MBWAY');
    }

    const data = await response.json();
    
    if (data.Status !== "000") {
      throw new Error(data.Message || 'Erro ao gerar pagamento MBWAY');
    }

    const payment: MBWayPayment = {
      orderId: order.id,
      orderNumber: order.orderNumber,
      requestId: data.RequestId,
      amount: parseFloat(order.total),
      phoneNumber,
      status: 'pending',
      createdAt: new Date().toISOString()
    };

    const paymentsRef = collection(db, 'mbway_payments');
    const docRef = await addDoc(paymentsRef, payment);
    return { ...payment, id: docRef.id };
  } catch (error) {
    console.error('Erro ao gerar pagamento MBWAY:', error);
    throw error;
  }
};

const getMBWayPayment = async (orderId: string): Promise<MBWayPayment | null> => {
  try {
    const paymentsRef = collection(db, 'mbway_payments');
    const q = query(paymentsRef, where('orderId', '==', orderId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return null;
    }

    const payment = snapshot.docs[0];
    return { id: payment.id, ...payment.data() } as MBWayPayment;
  } catch (error) {
    console.error('Erro ao buscar pagamento MBWAY:', error);
    throw error;
  }
};

const retryMBWayPayment = async (orderId: string): Promise<MBWayPayment> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      throw new Error('Encomenda não encontrada');
    }

    const orderData = orderDoc.data();
    if (!orderData.mbwayPhone) {
      throw new Error('Número de telemóvel MBWAY não encontrado');
    }

    return await generateMBWayPayment({
      ...orderData,
      id: orderId
    }, orderData.mbwayPhone);
  } catch (error) {
    console.error('Erro ao reenviar pagamento MBWAY:', error);
    throw error;
  }
};

const addProductReview = async (reviewData: {
  productId: string;
  userId: string;
  userName: string;
  userPhoto?: string | null;
  rating: number;
  comment: string;
  orderId: string;
  verified: boolean;
}) => {
  try {
    const reviewRef = doc(collection(db, 'product_reviews'));
    const review = {
      ...reviewData,
      createdAt: new Date().toISOString()
    };
    
    await setDoc(reviewRef, review);

    const orderRef = doc(db, 'orders', reviewData.orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (orderDoc.exists()) {
      const orderData = orderDoc.data();
      const updatedItems = orderData.items.map((item: any) => {
        if (item.productId === reviewData.productId) {
          return { ...item, reviewed: true };
        }
        return item;
      });

      await updateDoc(orderRef, { 
        items: updatedItems,
        updatedAt: new Date().toISOString()
      });

      const walletRef = doc(db, 'wallets', reviewData.userId);
      const walletSnapshot = await getDoc(walletRef);
      
      if (walletSnapshot.exists()) {
        const currentBalance = walletSnapshot.data().balance || 0;
        const newBalance = currentBalance + 0.50;

        await updateDoc(walletRef, { 
          balance: newBalance,
          updatedAt: new Date().toISOString()
        });

        await addDoc(collection(db, 'walletTransactions'), {
          userId: reviewData.userId,
          amount: 0.50,
          type: 'review_reward',
          description: 'Recompensa por avaliação de produto',
          createdAt: new Date().toISOString(),
          status: 'completed',
          orderId: reviewData.orderId,
          productId: reviewData.productId
        });
      }
    }

    return reviewRef.id;
  } catch (error: any) {
    console.error('Erro ao adicionar avaliação:', error);
    throw new Error(handleFirebaseError(error));
  }
};

export {
  app,
  auth,
  db,
  storage,
  functions,
  registerUser,
  loginWithEmail,
  sendPasswordResetEmail,
  createOrder,
  addOrderComment,
  addProductReview,
  generateMultibancoPayment,
  getMultibancoPayment,
  generateMBWayPayment,
  getMBWayPayment,
  retryMBWayPayment,
  handleFirebaseError
};