import { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface PendingReview {
  orderId: string;
  orderNumber: string;
  product: {
    id: string;
    name: string;
    mainImage?: string;
  };
}

export const usePendingReviews = (userId: string | undefined) => {
  const [pendingReviews, setPendingReviews] = useState<PendingReview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPendingReviews = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('buyerId', '==', userId),
          where('status', 'in', ['shipped', 'delivered'])
        );
        
        const snapshot = await getDocs(q);
        const pending: PendingReview[] = [];

        snapshot.docs.forEach(doc => {
          const order = doc.data();
          // Only include items that haven't been reviewed
          order.items.forEach(item => {
            if (!item.reviewed) {
              pending.push({
                orderId: doc.id,
                orderNumber: order.orderNumber,
                product: {
                  id: item.productId,
                  name: item.name,
                  mainImage: item.mainImage
                }
              });
            }
          });
        });

        setPendingReviews(pending);
        setError(null);
      } catch (err) {
        console.error('Error fetching pending reviews:', err);
        setError('Erro ao carregar avaliações pendentes');
      } finally {
        setLoading(false);
      }
    };

    fetchPendingReviews();
  }, [userId]);

  return { pendingReviews, loading, error };
};