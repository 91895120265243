import { db } from '../lib/firebase';
import { doc, runTransaction, increment, collection } from 'firebase/firestore';

interface OrderItem {
  productId: string;
  price: number;
  quantity: number;
  companyId: string;
}

interface PaymentDistribution {
  sellerId: string;
  amount: number;
  commission: number;
}

export const calculateCommission = (price: number): { 
  baseCommission: number;
  totalCommission: number;
  sellerAmount: number;
} => {
  // Comissão base de 15%
  const baseCommission = Number((price * 0.15).toFixed(2));
  
  // IVA de 23% sobre a comissão base
  const ivaAmount = Number((baseCommission * 0.23).toFixed(2));
  
  // Comissão total = comissão base + IVA
  const totalCommission = Number((baseCommission + ivaAmount).toFixed(2));
  
  // Valor para o vendedor = preço - comissão total
  const sellerAmount = Number((price - totalCommission).toFixed(2));

  return {
    baseCommission,
    totalCommission,
    sellerAmount
  };
};

export const distributeWalletPayment = async (
  orderItems: OrderItem[],
  orderNumber: string
): Promise<PaymentDistribution[]> => {
  const distributions: PaymentDistribution[] = [];
  const sellerTotals = new Map<string, { amount: number; commission: number }>();

  // Calculate amounts for each seller
  orderItems.forEach(item => {
    const itemTotal = item.price * item.quantity;
    const { sellerAmount, totalCommission } = calculateCommission(itemTotal);
    
    if (sellerTotals.has(item.companyId)) {
      const current = sellerTotals.get(item.companyId)!;
      sellerTotals.set(item.companyId, {
        amount: Number((current.amount + sellerAmount).toFixed(2)),
        commission: Number((current.commission + totalCommission).toFixed(2))
      });
    } else {
      sellerTotals.set(item.companyId, {
        amount: sellerAmount,
        commission: totalCommission
      });
    }
  });

  try {
    // Distribute payments to sellers
    for (const [sellerId, { amount, commission }] of sellerTotals.entries()) {
      await runTransaction(db, async (transaction) => {
        const sellerWalletRef = doc(db, 'wallets', sellerId);
        const transactionRef = doc(collection(db, 'walletTransactions'));
        
        // Add amount to seller's wallet
        transaction.update(sellerWalletRef, {
          balance: increment(amount)
        });

        // Record the commission transaction
        transaction.set(transactionRef, {
          userId: sellerId,
          orderId: orderNumber,
          amount: -commission, // Registra a comissão como valor negativo
          description: `Pagamento da encomenda #${orderNumber} (Comissão: €${commission})`,
          createdAt: new Date().toISOString(),
          status: 'completed',
          invoiceStatus: 'pending',
          invoiced: false,
          type: 'order_payment'
        });
      });

      distributions.push({
        sellerId,
        amount: Number(amount.toFixed(2)),
        commission: Number(commission.toFixed(2))
      });
    }

    return distributions;
  } catch (error) {
    console.error('Error distributing wallet payments:', error);
    throw new Error('Failed to distribute payments to sellers');
  }
};
