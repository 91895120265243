import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth, loginWithEmail } from '../../lib/firebase';
import ErrorModal from '../ErrorModal';

interface LoginFormProps {
  onClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Primeiro verificar se o email pertence a um vendedor
      const usersQuery = query(
        collection(db, 'users'),
        where('email', '==', formData.email.toLowerCase())
      );
      
      const userSnapshot = await getDocs(usersQuery);
      let userData = null;
      if (!userSnapshot.empty) {
        userData = userSnapshot.docs[0].data();
      }

      // Verifica se o usuário tem um papel restrito antes de tentar o login
      if (userData && (userData.role === 'gerente' || userData.role === 'funcionario')) {
        throw new Error('Vendedores não podem usar o site da LOGNOW. Para efectuar compras use o "Vendas Privadas" no painel de controlo.');
      }

      // Prossegue com o login apenas se o papel não for restrito
      const user = await loginWithEmail(formData.email, formData.password);
      if (user) {
        onClose();
        navigate('/client');
      }
    } catch (err: any) {
      console.error('Login error:', err);
      
      // Tratamento específico para erro de acesso de vendedores
      if (err.message === 'Vendedores não podem usar o site da LOGNOW. Para efectuar compras use o "Vendas Privadas" no painel de controlo.') {
        setError(err.message);
        setShowErrorModal(true);
      } else {
        setError(err.message);
      }
      
      setFormData({
        email: '',
        password: '',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  if (error && showErrorModal) {
    return (
      <ErrorModal
        isOpen={showErrorModal}
        message={error || ''}
        onClose={() => {
          setShowErrorModal(false);
          setError(null);
        }}
      />
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Palavra-passe
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          />
        </div>

        {error && !showErrorModal && (
          <div className="text-red-500 text-sm">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-secondary bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
        >
          {loading ? 'A iniciar sessão...' : 'Iniciar Sessão'}
        </button>
      </form>
    </>
  );
};

export default LoginForm;