import { useEffect, useState } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface ProductReview {
  id: string;
  productId: string;
  userId: string;
  userName: string;
  userPhoto?: string | null;
  rating: number;
  comment: string;
  verified: boolean;
  createdAt: string;
}

export const useProductReviews = (productId: string | undefined) => {
  const [reviews, setReviews] = useState<ProductReview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      if (!productId) {
        setLoading(false);
        return;
      }

      try {
        const reviewsRef = collection(db, 'product_reviews');
        const q = query(
          reviewsRef,
          where('productId', '==', productId),
          orderBy('createdAt', 'desc')
        );

        const snapshot = await getDocs(q);
        const reviewsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as ProductReview[];

        setReviews(reviewsData);

        // Calculate average rating
        if (reviewsData.length > 0) {
          const total = reviewsData.reduce((sum, review) => sum + review.rating, 0);
          setAverageRating(total / reviewsData.length);
        }

        setError(null);
      } catch (err: any) {
        console.error('Error fetching reviews:', err);
        // Handle offline/persistence errors gracefully
        if (err.code === 'failed-precondition') {
          // Try fetching without persistence
          try {
            const reviewsRef = collection(db, 'product_reviews');
            const q = query(
              reviewsRef,
              where('productId', '==', productId)
            );
            
            const snapshot = await getDocs(q);
            const reviewsData = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data()
              }))
              .sort((a, b) => 
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              ) as ProductReview[];

            setReviews(reviewsData);

            if (reviewsData.length > 0) {
              const total = reviewsData.reduce((sum, review) => sum + review.rating, 0);
              setAverageRating(total / reviewsData.length);
            }

            setError(null);
            return;
          } catch (retryErr) {
            console.error('Retry error:', retryErr);
          }
        }
        setError('Erro ao carregar avaliações');
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  return { reviews, averageRating, loading, error };
};