import React from 'react';
import TermsSection from '../TermsSection';

const ChapterTwo = () => {
  return (
    <>
      <TermsSection title="1. Disponibilidade dos Produtos">
        <p>
          Todos os produtos que se encontram no Site EM TUA CASA® estão disponíveis para encomenda, salvo rutura de stock. Os produtos disponíveis no Site EM TUA CASA® possuem um stock limitado, pelo que podem ficar indisponíveis sem aviso.
        </p>
      </TermsSection>

      <TermsSection title="2. Limitações de Compras no Site EM TUA CASA®">
        <p>
          A EM TUA CASA®, querendo disponibilizar a todos os seus clientes a oportunidade de adquirir os produtos publicitados no seu site, impõe as seguintes limitações cumulativas nas compras do site EM TUA CASA®:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>No âmbito das promoções efetuadas na campanha anual Black Friday, em cada encomenda apenas poderão ser adquiridas duas unidades de cada produto;</li>
          <li>Adicionalmente, em cada compra realizada no site EM TUA CASA®, independentemente de incluir produtos em promoção ou não, a encomenda não pode exceder o limite máximo de vinte produtos;</li>
          <li>Os portáteis, tablets, smartphones e consolas também têm um limite de duas unidades de cada produto por encomenda;</li>
          <li>As empresas ou empresários em nome individual têm limite máximo de €10.000,00 (dez mil euros) por cada encomenda.</li>
        </ul>
      </TermsSection>

      {/* Add all other sections from Chapter II */}
    </>
  );
};

export default ChapterTwo;