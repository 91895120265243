import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import FavoritesPage from './pages/FavoritesPage';
import CategoriesPage from './pages/CategoriesPage';
import CategoryProductsPage from './pages/CategoryProductsPage';
import SellerProfilePage from './pages/SellerProfilePage';
import ClientArea from './pages/ClientArea';
import WelcomePage from './pages/WelcomePage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import TrackOrderPage from './pages/TrackOrderPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import KuantoKustaFeed from './pages/KuantoKustaFeed';
import Sitemap from './pages/Sitemap';
import { useCart } from './contexts/CartContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Componente para renderizar o layout
const Layout = ({ children }: { children: React.ReactNode }) => {
  const { showAddAnimation } = useCart();
  
  return (
    <>
      {showAddAnimation && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg z-50 animate-fade-out">
          Produto adicionado ao carrinho!
        </div>
      )}
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <Navbar />
        <main className="flex-grow">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

function App() {
  return (
    <Router>
      <HelmetProvider>
        <AuthProvider>
          <CartProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/favorites" element={<FavoritesPage />} />
                <Route path="/categories" element={<CategoriesPage />} />
                <Route path="/category/:id" element={<CategoryProductsPage />} />
                <Route path="/seller/:id" element={<SellerProfilePage />} />
                <Route path="/client-area/*" element={<ClientArea />} />
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/order/:id" element={<OrderDetailsPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/track-order" element={<TrackOrderPage />} />
                <Route path="/order-confirmation/:id" element={<OrderConfirmationPage />} />
                <Route path="/kuantokusta-feed" element={<KuantoKustaFeed />} />
                <Route path="/sitemap" element={<Sitemap />} />
              </Routes>
              <ToastContainer />
            </Layout>
          </CartProvider>
        </AuthProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;