import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Product } from '../types';

export const useFavorites = (userId: string | undefined) => {
  const [favorites, setFavorites] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!userId) {
        setFavorites([]);
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFavorites(userData.favorites || []);
        } else {
          await setDoc(userRef, { favorites: [] });
          setFavorites([]);
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [userId]);

  const addToFavorites = async (product: Product) => {
    if (!userId) return;

    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        favorites: arrayUnion(product)
      });
      setFavorites(prev => [...prev, product]);
    } catch (error) {
      console.error('Error adding to favorites:', error);
    }
  };

  const removeFromFavorites = async (productId: string) => {
    if (!userId) return;

    try {
      const userRef = doc(db, 'users', userId);
      const productToRemove = favorites.find(p => p.id === productId);
      if (productToRemove) {
        await updateDoc(userRef, {
          favorites: arrayRemove(productToRemove)
        });
        setFavorites(prev => prev.filter(p => p.id !== productId));
      }
    } catch (error) {
      console.error('Error removing from favorites:', error);
    }
  };

  const isFavorite = (productId: string) => {
    return favorites.some(p => p.id === productId);
  };

  return {
    favorites,
    loading,
    addToFavorites,
    removeFromFavorites,
    isFavorite
  };
};
