import React from 'react';
import TermsSection from '../TermsSection';

const ChapterFour = () => {
  return (
    <>
      <TermsSection title="1. Dados Pessoais tratados pela LOGNOW">
        <p>
          O responsável pelo tratamento de Dados Pessoais do Cliente no Site LOGNOW é a Plataforma Concreta, Unipessoal Lda, melhor identificada no Capítulo I, que pode ser contactada através da linha de Apoio ao Cliente 220941891, disponível das 8:00 às 24:00, de Segunda a Sábado (Chamada para a rede fixa nacional).
        </p>
      </TermsSection>

      {/* Add all other sections from Chapter IV */}
    </>
  );
};

export default ChapterFour;