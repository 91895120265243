import React, { useState } from 'react';
import { Phone, Loader } from 'lucide-react';

interface MBWayPaymentProps {
  amount: number;
  onPaymentInitiated: (requestId: string, phone: string) => void;
  onError: (error: string) => void;
}

const MBWayPayment: React.FC<MBWayPaymentProps> = ({
  amount,
  onPaymentInitiated,
  onError
}) => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 9) {
      setPhone(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (phone.length !== 9) {
      setError('O número de telemóvel deve ter 9 dígitos');
      return;
    }

    setError(null);
    setLoading(true);

    try {
      // Store the phone number for later use
      onPaymentInitiated('pending', `+351${phone}`);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao processar pagamento';
      setError(errorMessage);
      onError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Phone className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              Introduza o seu número de telemóvel para pagar com MBWAY.
              Após confirmar, terá 4 minutos para aceitar o pagamento na app MBWAY.
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
            Número de telemóvel
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              +351
            </span>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={handlePhoneChange}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border-gray-300 focus:ring-primary focus:border-primary sm:text-sm"
              placeholder="912345678"
              required
              pattern="\d{9}"
              maxLength={9}
            />
          </div>
        </div>

        {error && (
          <div className="text-red-500 text-sm">
            {error}
          </div>
        )}

        <button
          type="submit"
          disabled={loading || phone.length !== 9}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-secondary bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
        >
          {loading ? (
            <>
              <Loader className="w-5 h-5 animate-spin mr-2" />
              A processar...
            </>
          ) : (
            'Confirmar número'
          )}
        </button>
      </form>
    </div>
  );
};

export default MBWayPayment;