import React from 'react';
import TermsSection from '../TermsSection';

const ChapterThree = () => {
  return (
    <>
      <TermsSection title="1. Introdução">
        <p>
          A EM TUA CASA® disponibiliza no Site EM TUA CASA®, um mercado online (designado "Marketplace EM TUA CASA®") que permite aos Clientes, maiores de 18 anos, após autenticação na Conta Online, a compra de Produtos a Vendedores externos à EM TUA CASA®, a preços e condições estabelecidas pelos próprios Vendedores.
        </p>
      </TermsSection>

      <TermsSection title="2. Funcionamento do Marketplace EM TUA CASA®">
        <p>
          O Marketplace da EM TUA CASA® permite ao Cliente consultar e comprar Produtos comercializados pelos Vendedores no Site EM TUA CASA®, bem como avaliar os Vendedores.
        </p>
      </TermsSection>

      {/* Add all other sections from Chapter III */}
    </>
  );
};

export default ChapterThree;