import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Heart, ShoppingBag } from 'lucide-react';
import { Product } from '../types';
import { useCompany } from '../hooks/useCompany';
import { useCart } from '../contexts/CartContext';
import { useAuth } from '../contexts/AuthContext';
import { useFavorites } from '../hooks/useFavorites';
import { isPromotionValid, getProductPrice } from '../utils/promotions';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const { company } = useCompany(product.companyId);
  const { addItem, clearCart } = useCart();
  const { user } = useAuth();
  const { addToFavorites, removeFromFavorites, isFavorite } = useFavorites(user?.uid);
  const isProductFavorite = isFavorite(product.id);

  const handleAddToCart = (e: React.MouseEvent) => {
    e.preventDefault();
    addItem(product, 1);
  };

  const handleBuyNow = (e: React.MouseEvent) => {
    e.preventDefault();
    clearCart();
    addItem(product, 1);
    navigate('/checkout');
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
      <Link to={`/product/${product.id}`} className="relative block">
        <img
          src={product.mainImage?.includes('storage.googleapis.com') 
            ? product.mainImage 
            : product.mainImage || 'https://plataformaconcreta.pt/logos/lognow/default-product.png'}
          alt={product.name}
          className="w-full h-48 object-cover"
          loading="lazy"
        />
        {isPromotionValid(product.promotionalPeriod) && product.salePrice && (
          <span className="absolute top-2 right-2 bg-primary text-secondary px-2 py-1 rounded-md text-sm font-medium">
            Promoção
          </span>
        )}
        <button
          className="absolute top-2 left-2 p-1 rounded-full bg-white/80 hover:bg-white transition-colors"
          onClick={(e) => {
            e.preventDefault();
            if (!user) {
              // TODO: Show auth modal
              return;
            }
            if (isProductFavorite) {
              removeFromFavorites(product.id);
            } else {
              addToFavorites(product);
            }
          }}
        >
          <Heart 
            className={`w-5 h-5 ${isProductFavorite ? 'fill-primary text-primary' : 'text-secondary'}`} 
          />
        </button>
      </Link>

      <div className="p-4 flex-grow flex flex-col">
        <Link
          to={`/seller/${company?.id}`}
          className="text-sm text-primary hover:underline mb-2"
          onClick={(e) => e.stopPropagation()}
        >
          Vendido por: {company?.commercialName || 'Carregando...'}
        </Link>

        <Link to={`/product/${product.id}`} className="flex-grow">
          <h3 className="text-lg font-medium text-secondary mb-2">{product.name}</h3>
        </Link>

        <div className="mt-2 flex items-baseline space-x-2">
          <span className="text-xl font-bold text-secondary">
            €{getProductPrice(product).toFixed(2)}
          </span>
          {isPromotionValid(product.promotionalPeriod) && product.salePrice && (
            <span className="text-sm text-gray-500 line-through">
              €{product.regularPrice.toFixed(2)}
            </span>
          )}
        </div>

        <div className="mt-4 space-y-2">
          <button 
            onClick={handleBuyNow}
            disabled={product.stockQuantity <= 0}
            className={`w-full py-2 rounded-md transition-colors ${
              product.stockQuantity <= 0 
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-primary text-secondary hover:bg-primary-dark'
            }`}
          >
            {product.stockQuantity <= 0 ? 'Fora de Stock' : 'Comprar Agora'}
          </button>
          <button 
            onClick={handleAddToCart}
            disabled={product.stockQuantity <= 0}
            className={`w-full py-2 rounded-md transition-colors flex items-center justify-center ${
              product.stockQuantity <= 0
                ? 'border border-gray-300 text-gray-500 cursor-not-allowed'
                : 'border border-primary text-secondary hover:bg-primary/10'
            }`}
          >
            <ShoppingBag className="w-5 h-5 mr-2" />
            {product.stockQuantity <= 0 ? 'Fora de Stock' : 'Adicionar ao Carrinho'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;