import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShieldCheck, Truck, CreditCard, Users } from 'lucide-react';

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-secondary mb-4">
          Bem-vindo à EM TUA CASA®!
        </h1>
        <p className="text-xl text-gray-600">
          Descubra as vantagens de fazer parte da nossa comunidade
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <ShieldCheck className="w-12 h-12 text-primary mb-4" />
          <h3 className="text-xl font-bold text-secondary mb-2">
            Compras Seguras
          </h3>
          <p className="text-gray-600">
            Todas as transações são protegidas e os vendedores são verificados
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Truck className="w-12 h-12 text-primary mb-4" />
          <h3 className="text-xl font-bold text-secondary mb-2">
            Envio Rápido
          </h3>
          <p className="text-gray-600">
            Entrega em todo Portugal com rastreamento em tempo real
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <CreditCard className="w-12 h-12 text-primary mb-4" />
          <h3 className="text-xl font-bold text-secondary mb-2">
            Pagamento Flexível
          </h3>
          <p className="text-gray-600">
            Múltiplas opções de pagamento seguras e convenientes
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <Users className="w-12 h-12 text-primary mb-4" />
          <h3 className="text-xl font-bold text-secondary mb-2">
            Comunidade Ativa
          </h3>
          <p className="text-gray-600">
            Interaja com outros compradores e vendedores da plataforma
          </p>
        </div>
      </div>

      <div className="text-center">
        <button
          onClick={() => navigate('/client')}
          className="bg-primary text-secondary px-8 py-3 rounded-lg font-medium hover:bg-primary-dark transition-colors"
        >
          Começar a Explorar
        </button>
      </div>
    </div>
  );
};

export default WelcomePage;