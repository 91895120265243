import { 
  ShoppingBag, 
  Home, 
  Utensils, 
  Shirt, 
  Gift, 
  Smartphone, 
  Baby, 
  Gamepad, 
  Book, 
  Heart, 
  Dumbbell, 
  Car, 
  PawPrint, 
  LucideIcon 
} from 'lucide-react';

const categoryIconMap: { [key: string]: LucideIcon } = {
  'geral': ShoppingBag,
  'casa': Home,
  'cozinha': Utensils,
  'moda': Shirt,
  'presentes': Gift,
  'tecnologia': Smartphone,
  'bebé': Baby,
  'jogos': Gamepad,
  'livros': Book,
  'saúde': Heart,
  'desporto': Dumbbell,
  'automóvel': Car,
  'animais': PawPrint
};

export const getIconFromName = (categoryName: string): LucideIcon => {
  const normalizedName = categoryName.toLowerCase().trim();
  return categoryIconMap[normalizedName] || ShoppingBag;
};
