import React from 'react';
import { Mail, Phone, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img src="https://plataformaconcreta.pt/logos/emtuacasa/logo_pretoemtuacasa.png" alt="EM TUA CASA®" className="h-12 mb-4" />
            <p className="text-gray-600">A sua plataforma de marketplace de confiança.</p>
          </div>
          
          <div>
            <h3 className="font-semibold text-lg mb-4 text-secondary">Vendedores</h3>
            <ul className="space-y-2">
              <li>
                <a href="https://controlo.emtuacasa.pt/register" className="text-gray-600 hover:text-primary">
                  Torne-se Vendedor
                </a>
              </li>
              <li>
                <a 
                  href="/feed/kuantokusta.xml"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="text-gray-600 hover:text-primary"
                >
                  Feed XML
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-semibold text-lg mb-4 text-secondary">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-primary">
                  Termos e Condições
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-primary">
                  Política de Privacidade
                </Link>
              </li>
              <li>
                <Link to="/complaints" className="text-gray-600 hover:text-primary">
                  Livro de Reclamações
                </Link>
              </li>
              <li>
                <Link to="/dispute" className="text-gray-600 hover:text-primary">
                  Resolução de Litígios
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-semibold text-lg mb-4 text-secondary">Contactos</h3>
            <ul className="space-y-3">
              <li className="flex items-center text-gray-600">
                <Mail className="w-5 h-5 mr-2 text-primary" />
                <a href="mailto:ola@emtuacasa.pt" className="text-gray-600 hover:text-primary">
                  ola@emtuacasa.pt
                </a>
              </li>
              <li className="flex items-center text-gray-600">
                <Phone className="w-5 h-5 mr-2 text-primary" />
                <div>
                  <a href="tel:+351221453060" className="text-gray-600 hover:text-primary">
                    +351 22 145 3060
                  </a>
                  <p className="text-xs text-gray-500">* Chamada rede fixa nacional</p>
                </div>
              </li>
              <li className="flex items-center text-gray-600">
                <Clock className="w-5 h-5 mr-2 text-primary" />
                <div>
                  <p>10h-12:30h</p>
                  <p>14h-16h</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t text-center">
          <p className="text-gray-600"> 2024 EM TUA CASA. Todos os direitos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;