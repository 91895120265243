import { useEffect, useState, useCallback, useRef } from 'react';
import { 
  collection, 
  query, 
  orderBy, 
  limit, 
  getDocs,
  onSnapshot,
  startAfter,
  QuerySnapshot,
  DocumentData,
  getCountFromServer
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';
import { isPromotionValid } from '../utils/promotions';

// Cache for storing products
const productsCache = new Map<string, Product>();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
let lastFetchTimestamp = 0;

// Skeleton data for initial load
const SKELETON_COUNT = 8;
const skeletonProducts = Array(SKELETON_COUNT).fill(null).map((_, index) => ({
  id: `skeleton-${index}`,
  isLoading: true
}));

export const useProducts = (limitCount = 8) => {
  const [products, setProducts] = useState<Product[]>(skeletonProducts as any);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const unsubscribeRef = useRef<(() => void) | null>(null);
  const initialLoadDone = useRef(false);

  const processProducts = useCallback((snapshot: QuerySnapshot<DocumentData>) => {
    const currentTime = Date.now();
    const productsData = snapshot.docs
      .map(doc => {
        const data = {
          id: doc.id,
          ...doc.data()
        } as Product;
        
        // Update cache
        productsCache.set(doc.id, {
          ...data,
          _cacheTimestamp: currentTime
        } as Product);
        
        return data;
      })
      .filter(product => 
        product.isPrivateSale === true &&
        product.salePrice < product.regularPrice &&
        product.stockQuantity > 0 &&
        isPromotionValid(product.promotionalPeriod)
      )
      .slice(0, limitCount);

    setProducts(productsData);
    lastFetchTimestamp = currentTime;
    initialLoadDone.current = true;
  }, [limitCount]);

  // Get total count of products
  useEffect(() => {
    const getTotalCount = async () => {
      try {
        const coll = collection(db, 'products');
        const snapshot = await getCountFromServer(coll);
        setTotalCount(snapshot.data().count);
      } catch (err) {
        console.error('Error getting total count:', err);
      }
    };
    getTotalCount();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const currentTime = Date.now();

        // Check if we have valid cached data
        if (currentTime - lastFetchTimestamp < CACHE_DURATION && !initialLoadDone.current) {
          const cachedProducts = Array.from(productsCache.values())
            .filter(product => 
              product.isPrivateSale === true &&
              product.salePrice < product.regularPrice &&
              product.stockQuantity > 0 &&
              isPromotionValid(product.promotionalPeriod)
            )
            .sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB.getTime() - dateA.getTime();
            })
            .slice(0, limitCount);

          if (cachedProducts.length > 0) {
            setProducts(cachedProducts);
            setLoading(false);
            return;
          }
        }

        // If no valid cache, fetch from Firestore
        const productsRef = collection(db, 'products');
        const q = query(
          productsRef,
          orderBy('createdAt', 'desc'),
          limit(limitCount * 2)
        );

        // Set up real-time listener
        const unsubscribe = onSnapshot(q, 
          (snapshot) => {
            processProducts(snapshot);
            setLoading(false);
          },
          (err) => {
            console.error('Error in real-time products update:', err);
            setError('Erro ao carregar produtos');
            setLoading(false);
          }
        );

        unsubscribeRef.current = unsubscribe;
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Erro ao carregar produtos');
        setLoading(false);
      }
    };

    fetchProducts();

    // Cleanup function
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [limitCount, processProducts]);

  return { products, loading, error, totalCount };
};