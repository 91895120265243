import React from 'react';
import { useCart } from '../contexts/CartContext';

const CartBadge = () => {
  const { totalItems } = useCart();

  if (totalItems === 0) return null;

  return (
    <span className="absolute -top-1 -right-1 bg-primary text-secondary text-xs font-bold px-2 py-1 rounded-full">
      {totalItems}
    </span>
  );
};

export default CartBadge;