import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSellerProfile } from '../hooks/useSellerProfile';
import { useSocialPosts } from '../hooks/useSocialPosts';
import { useSellerProducts } from '../hooks/useSellerProducts';
import { Mail, Phone, Clock, MapPin } from 'lucide-react';
import ProductCard from '../components/ProductCard';
import SocialPost from '../components/SocialPost';

const SellerProfilePage = () => {
  const { id } = useParams();
  const { profile, company, loading } = useSellerProfile(id);
  const { posts } = useSocialPosts(id);
  const { products } = useSellerProducts(id);
  const [activeTab, setActiveTab] = useState<'posts' | 'products'>('posts');

  if (loading || !profile || !company) {
    return <div className="h-screen flex items-center justify-center">Carregando...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      {/* Cover Image */}
      <div className="relative">
        <div className="w-full h-64 overflow-hidden rounded-lg">
          {profile.coverImage ? (
            <img 
              src={profile.coverImage}
              alt="Cover"
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gradient-to-r from-primary to-secondary" />
          )}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/30" />
        </div>

        {/* Company Logo */}
        <div className="absolute -bottom-16 left-8">
          <div className="w-32 h-32 rounded-full border-4 border-white overflow-hidden bg-white">
            <img
              src={company.logoBase64 || 'https://via.placeholder.com/128'}
              alt={company.commercialName}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>

      {/* Profile Info */}
      <div className="mt-20 px-8">
        <div className="flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="flex-1">
            <h1 className="text-3xl font-bold text-secondary mb-4">{company.commercialName}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2 text-gray-600">
                <div className="flex items-center gap-2">
                  <Mail className="w-4 h-4 text-primary" />
                  <a href={`mailto:${company.commercialEmail}`} className="hover:text-primary">
                    {company.commercialEmail}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <Phone className="w-4 h-4 text-primary" />
                  <a href={`tel:${company.commercialPhone}`} className="hover:text-primary">
                    {company.commercialPhone}
                  </a>
                </div>
              </div>
              <div className="space-y-2 text-gray-600">
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-primary" />
                  <span>{company.address}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4 text-primary" />
                  <span>{company.workingHours || '9H às 16H'}</span>
                </div>
              </div>
            </div>
            {profile.bio && (
              <div className="mt-4 text-gray-600">
                <p>{profile.bio}</p>
              </div>
            )}
          </div>
        </div>

        {/* Tabs */}
        <div className="mt-8 border-b border-gray-200">
          <div className="flex gap-8">
            <button
              onClick={() => setActiveTab('posts')}
              className={`pb-4 px-2 font-medium ${
                activeTab === 'posts'
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-gray-500'
              }`}
            >
              Posts
            </button>
            <button
              onClick={() => setActiveTab('products')}
              className={`pb-4 px-2 font-medium ${
                activeTab === 'products'
                  ? 'text-primary border-b-2 border-primary'
                  : 'text-gray-500'
              }`}
            >
              Produtos
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="py-8">
          {activeTab === 'posts' ? (
            <div className="space-y-6">
              {posts.map((post) => (
                <SocialPost key={post.id} post={post} company={company} />
              ))}
              {posts.length === 0 && (
                <div className="text-center text-gray-500 py-8">
                  Nenhum post encontrado
                </div>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
              {products.length === 0 && (
                <div className="text-center text-gray-500 py-8 col-span-3">
                  Nenhum produto encontrado
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerProfilePage;