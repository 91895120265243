import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Company } from '../types';

export const useRecentSellers = (limitCount = 6) => {
  const [sellers, setSellers] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const companiesRef = collection(db, 'companies');
        const q = query(
          companiesRef,
          orderBy('createdAt', 'desc'),
          limit(limitCount * 2)
        );
        
        const snapshot = await getDocs(q);
        const sellersData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(company => 
            company.commercialName && 
            company.logoBase64 &&
            company.commercialEmail &&
            company.commercialPhone &&
            company.district
          )
          .slice(0, limitCount) as Company[];

        setSellers(sellersData);
      } catch (err) {
        console.error('Error fetching sellers:', err);
        setError('Erro ao carregar vendedores');
      } finally {
        setLoading(false);
      }
    };

    fetchSellers();
  }, [limitCount]);

  return { sellers, loading, error };
};