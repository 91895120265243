import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingBag, Heart, User, Wallet, Menu as MenuIcon, X, Search } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useWallet } from '../hooks/useWallet';
import { auth } from '../lib/firebase';
import AuthModal from './auth/AuthModal';
import SearchBar from './SearchBar';
import CartBadge from './CartBadge';

const Navbar = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { wallet } = useWallet(user?.uid);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="bg-secondary text-white shadow-md relative z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Left Section */}
          <div className="flex items-center">
            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="lg:hidden p-2 rounded-md text-white hover:bg-secondary-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
            
            <Link to="/" className="ml-2 lg:ml-4">
              <img
                src="https://plataformaconcreta.pt/logos/emtuacasa/logo_branco_emtuacasa.png"
                alt="EM TUA CASA®"
                className="h-8 md:h-10"
              />
            </Link>

            {/* Desktop Navigation Links */}
            <div className="hidden lg:flex lg:ml-8 lg:space-x-8">
              <Link
                to="/categories"
                className="text-white hover:text-primary transition-colors py-2"
              >
                Categorias
              </Link>
              <Link
                to="/favorites"
                className="text-white hover:text-primary transition-colors py-2"
              >
                Favoritos
              </Link>
              <Link
                to="/track-order"
                className="text-white hover:text-primary transition-colors py-2"
              >
                Consultar Encomenda
              </Link>
            </div>
          </div>

          {/* Desktop Search Bar */}
          <div className="hidden lg:flex flex-1 items-center justify-center px-8 max-w-2xl">
            <SearchBar />
          </div>

          {/* Mobile Search Toggle */}
          <button
            onClick={() => setShowMobileSearch(!showMobileSearch)}
            className="lg:hidden p-2 text-white hover:text-primary transition-colors"
          >
            <Search className="h-6 w-6" />
          </button>

          {/* Right Section */}
          <div className="flex items-center space-x-1 sm:space-x-4">
            {user && wallet && (
              <div className="hidden sm:block text-sm text-white">
                <Wallet className="inline-block w-5 h-5 mr-1" />
                €{wallet.balance.toFixed(2)}
              </div>
            )}
            
            <Link
              to="/favorites"
              className="p-2 text-white hover:text-primary transition-colors lg:hidden"
              aria-label="Favoritos"
            >
              <Heart className="h-6 w-6" />
            </Link>

            <Link
              to="/cart"
              className="p-2 text-white hover:text-primary transition-colors relative"
              aria-label="Carrinho"
            >
              <ShoppingBag className="h-6 w-6" />
              <CartBadge />
            </Link>

            <div className="relative" ref={userMenuRef}>
              <button
                onClick={() => {
                  if (user) {
                    setIsUserMenuOpen(!isUserMenuOpen);
                  } else {
                    setIsAuthModalOpen(true);
                  }
                }}
                className="p-2 text-white hover:text-primary transition-colors"
                aria-label="Perfil"
              >
                <User className="h-6 w-6" />
              </button>

              {/* Desktop User Menu */}
              {isUserMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 origin-top-right">
                  {user && (
                    <div className="px-4 py-2 text-sm text-gray-700 border-b">
                      <p>Olá,</p>
                      <p className="font-medium">{user.displayName}</p>
                    </div>
                  )}
                  
                  <Link
                    to="/client"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsUserMenuOpen(false)}
                  >
                    Área de Cliente
                  </Link>
                  
                  <button
                    onClick={async () => {
                      await auth.signOut();
                      setIsUserMenuOpen(false);
                      navigate('/');
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Terminar Sessão
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Search Bar */}
        {showMobileSearch && (
          <div className="lg:hidden pb-4">
            <SearchBar />
          </div>
        )}
      </div>

      {/* Mobile Side Menu */}
      {isMenuOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 lg:hidden"
            onClick={closeMenu}
          />
          <div className="fixed inset-y-0 left-0 w-64 bg-secondary transform transition-transform duration-200 ease-in-out lg:hidden">
            <div className="h-16 flex items-center justify-between px-4 border-b border-secondary-light">
              <span className="font-medium text-white">Menu</span>
              <button
                onClick={closeMenu}
                className="p-2 rounded-md text-white hover:text-primary"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="py-4">
              {user && wallet && (
                <div className="px-4 py-2 border-b border-secondary-light mb-2">
                  <div className="flex items-center text-white">
                    <Wallet className="w-5 h-5 mr-2" />
                    <span>€{wallet.balance.toFixed(2)}</span>
                  </div>
                </div>
              )}
              <Link
                to="/categories"
                className="block px-4 py-2 text-white hover:bg-secondary-light"
                onClick={closeMenu}
              >
                Categorias
              </Link>
              <Link
                to="/favorites"
                className="block px-4 py-2 text-white hover:bg-secondary-light"
                onClick={closeMenu}
              >
                Favoritos
              </Link>
              <Link
                to="/track-order"
                className="block px-4 py-2 text-white hover:bg-secondary-light"
                onClick={closeMenu}
              >
                Consultar Encomenda
              </Link>
              <Link
                to="/cart"
                className="block px-4 py-2 text-white hover:bg-secondary-light"
                onClick={closeMenu}
              >
                Carrinho
              </Link>
            </div>
          </div>
        </>
      )}

      {/* Auth Modal */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </nav>
  );
};

export default Navbar;