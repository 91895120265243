import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export const useShippingCosts = () => {
  const [costs, setCosts] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'settings/shipping_costs'),
      (doc) => {
        if (doc.exists()) {
          setCosts(doc.data().costs || {});
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching shipping costs:', err);
        setError('Erro ao carregar custos de envio');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { costs, loading, error };
};