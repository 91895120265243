import React from 'react';
import TermsSection from '../TermsSection';

const ChapterFive = () => {
  return (
    <>
      <TermsSection title="1. Legislação aplicável">
        <p>
          Os presentes Termos & Condições e todos os contratos que vierem a ser celebrados ao abrigo dos mesmos regem-se pela legislação portuguesa.
        </p>
      </TermsSection>

      <TermsSection title="2. Resolução Alternativa de Litígios">
        <p>
          Em caso de litígios nacionais e transfronteiriços, iniciados por um consumidor contra um fornecedor de bens ou prestador de serviços que digam respeito a obrigações contratuais resultantes de contratos de compra e venda ou prestação de serviços, o consumidor pode recorrer à plataforma de resolução de litígios online da União Europeia em:
        </p>
        <a 
          href="https://ec.europa.eu/consumers/odr" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-primary hover:text-primary-dark underline block mb-4"
        >
          https://ec.europa.eu/consumers/odr
        </a>
        <p>
          Em caso de litígio, o consumidor pode recorrer ao Centro de Arbitragem de Conflitos de Consumo em:
        </p>
        <a 
          href="http://www.consumidor.pt" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-primary hover:text-primary-dark underline block"
        >
          www.consumidor.pt
        </a>
      </TermsSection>
    </>
  );
};

export default ChapterFive;