import React from 'react';
import HeroSection from '../components/HeroSection';
import FeaturedCategories from '../components/FeaturedCategories';
import ProductGrid from '../components/ProductGrid';
import RecentProducts from '../components/RecentProducts';
import RecentSellers from '../components/RecentSellers';

const HomePage = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <HeroSection />
      <FeaturedCategories />
      <ProductGrid />
      <RecentProducts />
      <RecentSellers />
    </div>
  );
};

export default HomePage;