import React, { useState } from 'react';
import TermsChapter from '../components/terms/TermsChapter';
import ChapterOne from '../components/terms/chapters/ChapterOne';
import ChapterTwo from '../components/terms/chapters/ChapterTwo';
import ChapterThree from '../components/terms/chapters/ChapterThree';
import ChapterFour from '../components/terms/chapters/ChapterFour';
import ChapterFive from '../components/terms/chapters/ChapterFive';

const TermsPage = () => {
  const [activeChapter, setActiveChapter] = useState(1);

  const chapters = [
    { id: 1, title: 'Capítulo I – Condições Gerais do Site LOGNOW', component: ChapterOne },
    { id: 2, title: 'Capítulo II – Condições de Compra à LOGNOW no Site LOGNOW', component: ChapterTwo },
    { id: 3, title: 'Capítulo III – Condições de Compra aos Vendedores no Marketplace LOGNOW', component: ChapterThree },
    { id: 4, title: 'Capítulo IV - Política relativa a dados pessoais', component: ChapterFour },
    { id: 5, title: 'Capítulo V – Lei aplicável e Resolução Alternativa de Litígios', component: ChapterFive },
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">Termos e Condições</h1>
      
      {/* Chapter Navigation */}
      <div className="mb-8 space-y-2">
        {chapters.map(chapter => (
          <button
            key={chapter.id}
            onClick={() => setActiveChapter(chapter.id)}
            className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
              activeChapter === chapter.id 
                ? 'bg-primary text-secondary font-medium'
                : 'hover:bg-gray-100'
            }`}
          >
            {chapter.title}
          </button>
        ))}
      </div>

      {/* Chapter Content */}
      <div className="prose max-w-none">
        {chapters.map(chapter => (
          <div key={chapter.id} className={activeChapter === chapter.id ? 'block' : 'hidden'}>
            <TermsChapter title={chapter.title}>
              <chapter.component />
            </TermsChapter>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsPage;