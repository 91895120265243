import React, { useState } from 'react';
import { ShippingAddress } from '../hooks/useShippingAddresses';
import { Plus, MapPin, Trash2, Loader } from 'lucide-react';

interface AddressSelectorProps {
  addresses: ShippingAddress[];
  selectedAddressId: string | null;
  onSelect: (address: ShippingAddress) => void;
  onNewAddress: () => void;
  onDelete: (addressId: string) => Promise<void>;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  addresses,
  selectedAddressId,
  onSelect,
  onNewAddress,
  onDelete
}) => {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState<string | null>(null);

  const handleDelete = async (addressId: string) => {
    try {
      setDeletingId(addressId);
      await onDelete(addressId);
      setShowConfirmDelete(null);
    } catch (error) {
      console.error('Erro ao eliminar morada:', error);
      alert('Erro ao eliminar morada. Por favor, tente novamente.');
    } finally {
      setDeletingId(null);
    }
  };

  return (
    <div className="space-y-4">
      {addresses.length === 0 ? (
        <div className="text-center text-gray-500 py-8">
          <MapPin className="w-8 h-8 mx-auto mb-2" />
          <p>Nenhuma morada de entrega registada</p>
        </div>
      ) : (
        addresses.map((address) => (
          <div
            key={address.id}
            className={`border rounded-lg p-4 transition-colors ${
              selectedAddressId === address.id
                ? 'border-primary bg-primary/5'
                : 'hover:border-gray-400'
            }`}
          >
            <div className="flex items-start justify-between">
              <div 
                className="flex items-start space-x-3 flex-1 cursor-pointer"
                onClick={() => onSelect(address)}
              >
                <MapPin className={`w-5 h-5 mt-1 ${
                  selectedAddressId === address.id ? 'text-primary' : 'text-gray-400'
                }`} />
                <div>
                  <p className="font-medium text-secondary">{address.name}</p>
                  <p className="text-gray-600">{address.address}</p>
                  <p className="text-gray-600">
                    {address.postalCode} {address.city}
                  </p>
                  <p className="text-gray-600">{address.district}</p>
                  <div className="mt-1 text-sm text-gray-500">
                    <p>{address.phone}</p>
                    <p>{address.email}</p>
                    {address.taxNumber && <p>NIF: {address.taxNumber}</p>}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end space-y-2">
                {address.isDefault && (
                  <span className="text-xs text-primary font-medium px-2 py-1 bg-primary/10 rounded-full">
                    Morada predefinida
                  </span>
                )}
                {showConfirmDelete === address.id ? (
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleDelete(address.id)}
                      disabled={deletingId === address.id}
                      className="text-red-500 hover:text-red-700 text-sm font-medium"
                    >
                      {deletingId === address.id ? (
                        <Loader className="w-4 h-4 animate-spin" />
                      ) : (
                        'Confirmar'
                      )}
                    </button>
                    <button
                      onClick={() => setShowConfirmDelete(null)}
                      className="text-gray-500 hover:text-gray-700 text-sm"
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setShowConfirmDelete(address.id)}
                    className="text-red-500 hover:text-red-700 p-1"
                    title="Remover morada"
                    disabled={deletingId !== null}
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      )}

      <button
        onClick={onNewAddress}
        className="w-full border-2 border-dashed border-gray-300 rounded-lg p-4 text-gray-500 hover:border-primary hover:text-primary transition-colors flex items-center justify-center space-x-2"
      >
        <Plus className="w-5 h-5" />
        <span>Adicionar nova morada</span>
      </button>
    </div>
  );
};

export default AddressSelector;