import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface WalletTransaction {
  id: string;
  amount: number;
  createdAt: string;
  description: string;
  invoicePdf?: string;
  invoiceStatus?: string;
  invoiced?: boolean;
  orderId: string;
  status: string;
  type: string;
  updatedAt: string;
  userId: string;
}

export const useWalletTransaction = (orderId: string | undefined) => {
  const [transaction, setTransaction] = useState<WalletTransaction | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTransaction = async () => {
      if (!orderId) {
        setLoading(false);
        return;
      }

      try {
        const transactionsRef = collection(db, 'walletTransactions');
        const q = query(
          transactionsRef,
          where('orderId', '==', orderId),
          where('type', '==', 'order_payment')
        );
        
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const transactionData = {
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data()
          } as WalletTransaction;
          setTransaction(transactionData);
        }
      } catch (err) {
        console.error('Error fetching transaction:', err);
        setError('Error fetching invoice information');
      } finally {
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [orderId]);

  return { transaction, loading, error };
};

export default useWalletTransaction;