import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCategories } from '../hooks/useCategories';
import { Loader, ChevronRight } from 'lucide-react';

const FeaturedCategories = () => {
  const navigate = useNavigate();
  const { categories, loading, error } = useCategories();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-4">
        {error}
      </div>
    );
  }

  // Only show first 6 categories in a single row
  const displayedCategories = categories.slice(0, 6);
  const hasMoreCategories = categories.length > 6;

  return (
    <div className="my-12">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-secondary">Categorias</h2>
        {hasMoreCategories && (
          <button
            onClick={() => navigate('/categories')}
            className="flex items-center text-primary hover:text-primary-dark"
          >
            Ver todas
            <ChevronRight className="w-5 h-5 ml-1" />
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {displayedCategories.map((category) => {
          const Icon = category.icon;
          return (
            <button
              key={category.id}
              onClick={() => navigate(`/category/${category.slug}`)}
              className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
            >
              <Icon className="w-8 h-8 text-primary mb-2" />
              <span className="text-secondary font-medium text-center">{category.name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedCategories;