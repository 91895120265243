import React, { useState } from 'react';
import { X } from 'lucide-react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, onClose }) => {
  const [view, setView] = useState<'login' | 'register' | 'forgot'>('login');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4">
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
        
        <div className="relative bg-white rounded-lg w-full max-w-md p-6">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <X size={24} />
          </button>

          <div className="mb-6">
            <div className="flex justify-center space-x-4 mb-6">
              <button
                className={`px-4 py-2 ${
                  view === 'login'
                    ? 'text-primary border-b-2 border-primary'
                    : 'text-gray-500'
                }`}
                onClick={() => setView('login')}
              >
                Iniciar Sessão
              </button>
              <button
                className={`px-4 py-2 ${
                  view === 'register'
                    ? 'text-primary border-b-2 border-primary'
                    : 'text-gray-500'
                }`}
                onClick={() => setView('register')}
              >
                Registar
              </button>
            </div>

            {view === 'login' && (
              <>
                <LoginForm onClose={onClose} />
                <button
                  className="mt-4 text-sm text-primary hover:text-primary-dark"
                  onClick={() => setView('forgot')}
                >
                  Esqueceu a palavra-passe?
                </button>
              </>
            )}
            {view === 'register' && <RegisterForm onClose={onClose} />}
            {view === 'forgot' && (
              <>
                <ForgotPasswordForm onClose={onClose} />
                <button
                  className="mt-4 text-sm text-primary hover:text-primary-dark"
                  onClick={() => setView('login')}
                >
                  Voltar à página inicial
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;