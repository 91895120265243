import React, { createContext, useContext, useState, useEffect } from 'react';
import { Product } from '../types';
import { toast } from 'react-toastify'; // Import toastify

interface CartItem {
  product: Product;
  quantity: number;
}

interface CartContextType {
  items: CartItem[];
  addItem: (product: Product, quantity: number) => void;
  removeItem: (productId: string) => void;
  updateQuantity: (productId: string, quantity: number) => void;
  clearCart: () => void;
  totalItems: number;
  showAddAnimation: boolean;
}

const CartContext = createContext<CartContextType>({
  items: [],
  addItem: () => {},
  removeItem: () => {},
  updateQuantity: () => {},
  clearCart: () => {},
  totalItems: 0,
  showAddAnimation: false
});

export const useCart = () => useContext(CartContext);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<CartItem[]>([]);
  const [showAddAnimation, setShowAddAnimation] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    // Calculate total items whenever cart changes
    const total = items.reduce((sum, item) => sum + item.quantity, 0);
    setTotalItems(total);
  }, [items]);

  const addItem = (product: Product, quantity: number) => {
    if (product.stockQuantity <= 0) {
      toast.error('Este produto está fora de estoque');
      return;
    }

    setItems((currentItems) => {
      const existingItem = currentItems.find((item) => item.product.id === product.id);
      
      if (existingItem) {
        // Verificar se a nova quantidade não excede o estoque
        const newQuantity = existingItem.quantity + quantity;
        if (newQuantity > product.stockQuantity) {
          toast.error('Quantidade solicitada excede o estoque disponível');
          return currentItems;
        }

        return currentItems.map((item) =>
          item.product.id === product.id
            ? { ...item, quantity: newQuantity }
            : item
        );
      }

      // Verificar se a quantidade inicial não excede o estoque
      if (quantity > product.stockQuantity) {
        toast.error('Quantidade solicitada excede o estoque disponível');
        return currentItems;
      }

      return [...currentItems, { product, quantity }];
    });

    // Show animation
    setShowAddAnimation(true);
    setTimeout(() => setShowAddAnimation(false), 1000);
  };

  const removeItem = (productId: string) => {
    setItems((currentItems) =>
      currentItems.filter((item) => item.product.id !== productId)
    );
  };

  const updateQuantity = (productId: string, quantity: number) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.product.id === productId ? { ...item, quantity } : item
      )
    );
  };

  const clearCart = () => {
    setItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        updateQuantity,
        clearCart,
        totalItems,
        showAddAnimation
      }}
    >
      {children}
    </CartContext.Provider>
  );
};