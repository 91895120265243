import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useCart } from '../contexts/CartContext';
import { useWallet } from '../hooks/useWallet';
import { useShippingAddresses } from '../hooks/useShippingAddresses';
import { useShippingCosts } from '../hooks/useShippingCosts';
import { createOrder } from '../lib/firebase';
import { Loader, CreditCard, Landmark } from 'lucide-react';
import AddressForm from '../components/AddressForm';
import AddressSelector from '../components/AddressSelector';
import MBWayPayment from '../components/MBWayPayment';
import RegisterForm from '../components/auth/RegisterForm';
import { distributeWalletPayment } from '../utils/walletPaymentUtils';

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { items, clearCart, totalItems } = useCart();
  const { wallet } = useWallet(user?.uid);
  const { addresses, loading: addressesLoading } = useShippingAddresses(user?.uid);
  const { costs } = useShippingCosts();
  
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<'mbway' | 'multibanco' | null>(null);
  const [mbwayPhone, setMbwayPhone] = useState<string | null>(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState<'account' | 'address' | 'payment'>(
    user ? 'address' : 'account'
  );
  const [uniqueSellers, setUniqueSellers] = useState(new Set());
  const [shippingCostPerSeller, setShippingCostPerSeller] = useState<Record<string, number>>({});

  // Calculate totals
  const subtotal = items.reduce((sum, item) => {
    const price = item.product.salePrice || item.product.regularPrice;
    return sum + (price * item.quantity);
  }, 0);
  const total = subtotal + shippingCost;
  const walletBalance = wallet?.balance || 0;
  const remainingAfterWallet = Math.max(0, total - walletBalance);

  React.useEffect(() => {
    if (totalItems === 0) {
      navigate('/cart');
    }
  }, [totalItems, navigate]);

  React.useEffect(() => {
    const sellers = new Set();
    items.forEach(item => {
      if (item.product.companyId) {
        sellers.add(item.product.companyId);
      }
    });
    setUniqueSellers(sellers);

    // Calculate shipping cost based on selected address and costs from database
    if (selectedAddressId) {
      const selectedAddress = addresses.find(addr => addr.id === selectedAddressId);
      if (selectedAddress?.district && costs[selectedAddress.district]) {
        const totalShippingCost = Array.from(sellers).reduce((total) => {
          return total + costs[selectedAddress.district];
        }, 0);
        setShippingCost(totalShippingCost);
      }
    }
  }, [items, selectedAddressId, addresses, costs]);

  const handleAddressSelect = (address: any) => {
    console.log('Selecting address:', address);
    setSelectedAddressId(address.id);
    setCheckoutStep('address'); // Ensure we're on the address step
    
    if (address.district && costs[address.district]) {
      const totalShippingCost = Array.from(uniqueSellers).reduce((total) => {
        return total + costs[address.district];
      }, 0);
      console.log('New shipping cost:', totalShippingCost);
      setShippingCost(totalShippingCost);
    }
  };

  const handleAddressSubmit = (address: any) => {
    setShowAddressForm(false);
    if (address.district && costs[address.district]) {
      const totalShippingCost = Array.from(uniqueSellers).reduce((total) => {
        return total + costs[address.district];
      }, 0);
      setShippingCost(totalShippingCost);
    }
  };

  const handleMBWayPaymentInitiated = (requestId: string, phone: string) => {
    setMbwayPhone(phone);
  };

  const handlePlaceOrder = async () => {
    if (!selectedAddressId) {
      setError('Por favor, selecione uma morada de entrega');
      return;
    }

    if (remainingAfterWallet > 0 && !paymentMethod) {
      setError('Por favor, selecione um método de pagamento');
      return;
    }

    if (paymentMethod === 'mbway' && !mbwayPhone) {
      setError('Por favor, introduza o número de telemóvel para MBWAY');
      return;
    }

    if (!user) {
      setError('Por favor, faça login para continuar');
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      const selectedAddress = addresses.find(addr => addr.id === selectedAddressId);
      if (!selectedAddress) {
        throw new Error('Morada de entrega não encontrada');
      }

      const orderData = {
        buyerId: user.uid,
        items: items.map(item => ({
          productId: item.product.id,
          name: item.product.name,
          price: item.product.salePrice || item.product.regularPrice,
          quantity: item.quantity,
          mainImage: item.product.mainImage,
          total: (item.product.salePrice || item.product.regularPrice) * item.quantity,
          product: item.product,
          companyId: item.product.companyId
        })),
        shippingAddress: {
          ...selectedAddress,
          country: 'Portugal'
        },
        shippingMethod: 'normal',
        shippingCost,
        subtotal,
        total,
        orderNumber: `PS${Date.now()}${Math.floor(Math.random() * 1000)}`,
        paymentMethod,
        useWallet: walletBalance > 0,
        walletAmount: Math.min(walletBalance, total),
        isOnlineSale: true,
        createdBy: user.uid,
        ...(paymentMethod === 'mbway' && mbwayPhone ? { mbwayPhone } : {})
      };

      // Process wallet payment first if wallet is being used
      if (orderData.useWallet) {
        try {
          const orderId = await createOrder(orderData);
          clearCart();
          navigate(`/order-confirmation/${orderId}`);
        } catch (error) {
          console.error('Error processing wallet payment:', error);
          throw new Error('Failed to process wallet payment');
        }
      } else {
        const orderId = await createOrder(orderData);
        clearCart();
        navigate(`/order-confirmation/${orderId}`);
      }
    } catch (err: any) {
      console.error('Erro ao processar encomenda:', err);
      setError(err.message || 'Erro ao processar encomenda. Por favor, tente novamente.');
    } finally {
      setProcessing(false);
    }
  };

  if (addressesLoading) {
    return (
      <div className="flex justify-center items-center h-96">
        <Loader className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-secondary mb-8">Checkout</h1>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          {/* Account Step */}
          {checkoutStep === 'account' && !user && (
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              <h2 className="text-xl font-semibold text-secondary mb-4">
                Já tem uma conta?
              </h2>
              {!showAddressForm ? (
                <>
                  <p className="text-gray-600 mb-4">
                    Crie uma conta para acompanhar as suas encomendas e ganhar recompensas
                  </p>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setShowAddressForm(true)}
                      className="bg-primary text-secondary px-6 py-2 rounded-md hover:bg-primary-dark transition-colors"
                    >
                      Criar Conta
                    </button>
                    <button
                      onClick={() => setCheckoutStep('address')}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      Continuar como visitante
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <RegisterForm 
                    onClose={() => setShowAddressForm(false)}
                    redirectTo={false}
                  />
                  <button
                    onClick={() => {
                      setShowAddressForm(false);
                      setCheckoutStep('address');
                    }}
                    className="mt-4 text-gray-600 hover:text-gray-800"
                  >
                    Voltar para checkout como visitante
                  </button>
                </>
              )}
            </div>
          )}

          {/* Address Step */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-xl font-semibold text-secondary mb-4">
              Morada de Entrega
            </h2>
            {showAddressForm ? (
              <AddressForm
                onSuccess={handleAddressSubmit}
                onCancel={() => setShowAddressForm(false)}
              />
            ) : (
              <>
                <AddressSelector
                  addresses={addresses}
                  selectedAddressId={selectedAddressId}
                  onSelect={(address) => {
                    console.log('AddressSelector onSelect called with:', address);
                    handleAddressSelect(address);
                  }}
                  onNewAddress={() => setShowAddressForm(true)}
                  onDelete={() => {}}
                />
                {addresses.length === 0 && (
                  <button
                    onClick={() => setShowAddressForm(true)}
                    className="mt-4 w-full bg-primary text-secondary py-2 rounded-md hover:bg-primary-dark transition-colors"
                  >
                    Adicionar Nova Morada
                  </button>
                )}
              </>
            )}
          </div>

          {/* Payment Method Section */}
          {selectedAddressId && remainingAfterWallet > 0 && (
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold text-secondary mb-4">
                Método de Pagamento
              </h2>
              <div className="space-y-4">
                <button
                  onClick={() => setPaymentMethod('mbway')}
                  className={`w-full p-4 border rounded-lg text-left flex items-start space-x-4 ${
                    paymentMethod === 'mbway'
                      ? 'border-primary bg-primary/5'
                      : 'hover:border-gray-400'
                  }`}
                >
                  <CreditCard className="w-6 h-6 text-primary mt-1" />
                  <div>
                    <div className="font-medium text-secondary">MB WAY</div>
                    <div className="text-sm text-gray-500">
                      Pagamento instantâneo através do seu telemóvel
                    </div>
                  </div>
                </button>

                <button
                  onClick={() => setPaymentMethod('multibanco')}
                  className={`w-full p-4 border rounded-lg text-left flex items-start space-x-4 ${
                    paymentMethod === 'multibanco'
                      ? 'border-primary bg-primary/5'
                      : 'hover:border-gray-400'
                  }`}
                >
                  <Landmark className="w-6 h-6 text-primary mt-1" />
                  <div>
                    <div className="font-medium text-secondary">Multibanco</div>
                    <div className="text-sm text-gray-500">
                      Pagamento por referência multibanco
                    </div>
                  </div>
                </button>

                {paymentMethod === 'mbway' && (
                  <div className="mt-4">
                    <MBWayPayment
                      amount={remainingAfterWallet}
                      onPaymentInitiated={handleMBWayPaymentInitiated}
                      onError={(error) => setError(error)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Order Summary */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-secondary mb-4">
              Resumo da Encomenda
            </h2>
            
            <div className="space-y-4 text-sm">
              {items.map((item) => (
                <div key={item.product.id} className="flex justify-between py-2">
                  <div className="flex-1">
                    <p className="text-gray-600">
                      {item.quantity}x {item.product.name}
                    </p>
                  </div>
                  <p className="text-gray-900">
                    €{((item.product.salePrice || item.product.regularPrice) * item.quantity).toFixed(2)}
                  </p>
                </div>
              ))}

              <div className="border-t pt-4 space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Subtotal</span>
                  <span>€{subtotal.toFixed(2)}</span>
                </div>
                
                <div className="flex justify-between">
                  <span className="text-gray-600">
                    Portes de envio{uniqueSellers.size > 1 ? ` (${uniqueSellers.size} vendedores)` : ''}
                  </span>
                  <span>
                    {selectedAddressId
                      ? `€${shippingCost.toFixed(2)}`
                      : 'Selecione a morada'}
                  </span>
                </div>

                {user && walletBalance > 0 && (
                  <div className="flex justify-between text-green-600">
                    <span>Saldo Wallet</span>
                    <span>-€{Math.min(walletBalance, total).toFixed(2)}</span>
                  </div>
                )}

                <div className="border-t pt-2 mt-2">
                  <div className="flex justify-between font-medium">
                    <span>Total</span>
                    <span>€{remainingAfterWallet.toFixed(2)}</span>
                  </div>
                </div>
              </div>

              {error && (
                <div className="text-red-500 text-sm mt-4">{error}</div>
              )}

              {selectedAddressId && (
                <button
                  onClick={handlePlaceOrder}
                  disabled={
                    processing ||
                    !selectedAddressId ||
                    (remainingAfterWallet > 0 && !paymentMethod) ||
                    (paymentMethod === 'mbway' && !mbwayPhone)
                  }
                  className="w-full bg-primary text-secondary py-3 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50"
                >
                  {processing ? (
                    <span className="flex items-center justify-center">
                      <Loader className="w-5 h-5 animate-spin mr-2" />
                      A processar...
                    </span>
                  ) : (
                    'Finalizar Compra'
                  )}
                </button>
              )}

              {user && remainingAfterWallet === 0 && (
                <p className="text-sm text-green-600 text-center mt-2">
                  O valor total será debitado da sua wallet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;