import React from 'react';

interface TermsChapterProps {
  title: string;
  children: React.ReactNode;
}

const TermsChapter: React.FC<TermsChapterProps> = ({ title, children }) => {
  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-secondary mb-6">{title}</h2>
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
};

export default TermsChapter;