import React from 'react';
import { Heart, MessageCircle, Share2 } from 'lucide-react';
import { SocialPost as SocialPostType, Company } from '../types';
import { formatDate } from '../utils/dateUtils';
import { useUser } from '../hooks/useUser';
import { useProduct } from '../hooks/useProduct';

interface SocialPostProps {
  post: SocialPostType;
  company: Company;
}

const SocialPost: React.FC<SocialPostProps> = ({ post, company }) => {
  const { user } = useUser(post.userId);

  return (
    <div className="bg-white rounded-lg shadow-sm border p-6">
      {/* Post Header */}
      <div className="flex items-center space-x-3">
        <img
          src={user?.photoBase64 || 'https://via.placeholder.com/40'}
          alt={user?.displayName || company.commercialName}
          className="w-10 h-10 rounded-full object-cover"
        />
        <div>
          <div className="font-medium text-secondary">
            {user?.displayName || company.commercialName}
          </div>
          <div className="text-sm text-gray-500">
            {formatDate(post.createdAt)}
          </div>
        </div>
      </div>

      {/* Post Content */}
      <div className="mt-4">
        <p className="text-gray-800">{post.content}</p>
        
        {/* Shared Post */}
        {post.sharedPost && (
          <div className="mt-4 border rounded-lg p-4 bg-gray-50">
            <div className="flex items-center space-x-2 mb-2">
              <div className="font-medium text-secondary">
                {post.sharedPost.originalAuthor.name}
              </div>
            </div>
            <p className="text-gray-800">{post.sharedPost.content}</p>
            {post.sharedPost.images?.length > 0 && (
              <div className="mt-4 grid grid-cols-2 gap-2">
                {post.sharedPost.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Shared post image ${index + 1}`}
                    className="rounded-lg w-full h-48 object-cover"
                  />
                ))}
              </div>
            )}
          </div>
        )}
        
        {/* Post Images */}
        {post.images?.length > 0 && (
          <div className="mt-4 grid grid-cols-2 gap-2">
            {post.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Post image ${index + 1}`}
                className="rounded-lg w-full h-48 object-cover"
              />
            ))}
          </div>
        )}

        {/* Products Tagged */}
        {post.products && post.products.length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-500 mb-2">
              Produtos mencionados:
            </h4>
            <div className="flex space-x-4 overflow-x-auto">
              {post.products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        )}

        {/* Post Actions */}
        <div className="flex items-center justify-between mt-4 pt-4 border-t">
          <div className="flex space-x-6">
            <button className="flex items-center space-x-1 text-gray-500 hover:text-primary">
              <Heart className="w-5 h-5" />
              <span>{post.likes?.length || 0}</span>
            </button>
            <button className="flex items-center space-x-1 text-gray-500 hover:text-primary">
              <MessageCircle className="w-5 h-5" />
              <span>{post.comments?.length || 0}</span>
            </button>
            <button className="flex items-center space-x-1 text-gray-500 hover:text-primary">
              <Share2 className="w-5 h-5" />
              <span>{post.shares?.length || 0}</span>
            </button>
          </div>
        </div>

        {/* Comments */}
        {post.comments?.length > 0 && (
          <div className="mt-4 space-y-4">
            {post.comments.map((comment, index) => {
              const { user: commentUser } = useUser(comment.userId);
              return (
                <div key={index} className="flex space-x-3">
                  <img
                    src={commentUser?.photoBase64 || 'https://via.placeholder.com/32'}
                    alt={comment.userName}
                    className="h-8 w-8 rounded-full object-cover"
                  />
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {comment.userName}
                    </div>
                    <div className="text-sm text-gray-500">
                      {comment.content}
                    </div>
                    <div className="text-xs text-gray-400">
                      {formatDate(comment.createdAt)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

// Product Card Component
const ProductCard = ({ product }) => {
  const { product: productDetails } = useProduct(product.id);

  if (!productDetails) {
    return null;
  }

  return (
    <div className="flex-shrink-0 w-32 space-y-1">
      <img
        src={productDetails.mainImage || 'https://via.placeholder.com/128'}
        alt={productDetails.name}
        className="w-32 h-32 object-cover rounded-md"
      />
      <p className="text-sm font-medium truncate">
        {productDetails.name}
      </p>
      <p className="text-sm text-primary">
        €{(productDetails.salePrice || productDetails.regularPrice).toFixed(2)}
      </p>
    </div>
  );
};

export default SocialPost;