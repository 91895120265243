import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';

interface MBWayCountdownProps {
  onTimeout: () => void;
}

const MBWayCountdown: React.FC<MBWayCountdownProps> = ({ onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState(240); // 4 minutos em segundos

  useEffect(() => {
    if (timeLeft === 0) {
      onTimeout();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onTimeout]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <Clock className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Por favor, aceite o pagamento na app MBWAY.
            Tempo restante: {minutes}:{seconds.toString().padStart(2, '0')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MBWayCountdown;