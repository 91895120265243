import React, { useState } from 'react';
import { Star, Loader } from 'lucide-react';
import { addProductReview } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';

interface ReviewFormProps {
  productId: string;
  productName: string;
  orderId: string;
  onSuccess: () => void;
}

const ReviewForm: React.FC<ReviewFormProps> = ({
  productId,
  productName,
  orderId,
  onSuccess
}) => {
  const { user } = useAuth();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [hoveredRating, setHoveredRating] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      setError('Você precisa estar logado para enviar uma avaliação');
      return;
    }
    
    if (rating === 0) {
      setError('Por favor, selecione uma classificação');
      return;
    }

    if (!comment.trim()) {
      setError('Por favor, adicione um comentário');
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      await addProductReview({
        productId,
        userId: user.uid,
        userName: user.displayName || 'Utilizador',
        userPhoto: user.photoURL,
        rating,
        comment: comment.trim(),
        orderId,
        verified: true
      });
      
      onSuccess();
    } catch (err: any) {
      console.error('Error submitting review:', err);
      setError(err.message || 'Erro ao enviar avaliação. Por favor, tente novamente.');
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Avaliação para {productName}
        </label>
        <div className="flex space-x-1">
          {[1, 2, 3, 4, 5].map((value) => (
            <button
              key={value}
              type="button"
              onClick={() => setRating(value)}
              onMouseEnter={() => setHoveredRating(value)}
              onMouseLeave={() => setHoveredRating(0)}
              className="focus:outline-none"
            >
              <Star
                className={`w-6 h-6 ${
                  value <= (hoveredRating || rating)
                    ? 'text-yellow-400 fill-current'
                    : 'text-gray-300'
                }`}
              />
            </button>
          ))}
        </div>
        <p className="text-sm text-green-600 mt-1">
          Ganhe €0,50 na sua wallet ao avaliar este produto!
        </p>
      </div>

      <div>
        <label htmlFor="comment" className="block text-sm font-medium text-gray-700 mb-1">
          Comentário
        </label>
        <textarea
          id="comment"
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
          placeholder="Conte-nos a sua experiência com este produto..."
          required
        />
      </div>

      {error && (
        <div className="text-red-500 text-sm">{error}</div>
      )}

      <button
        type="submit"
        disabled={submitting || rating === 0 || !comment.trim()}
        className="w-full bg-primary text-secondary py-2 px-4 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center justify-center"
      >
        {submitting ? (
          <>
            <Loader className="w-5 h-5 animate-spin mr-2" />
            A enviar...
          </>
        ) : (
          'Enviar Avaliação'
        )}
      </button>
    </form>
  );
};

export default ReviewForm;