export const isPromotionValid = (promotionalPeriod: { startDate: string; endDate: string } | undefined | null): boolean => {
  if (!promotionalPeriod) return false;

  const now = new Date();
  const startDate = new Date(promotionalPeriod.startDate);
  const endDate = new Date(promotionalPeriod.endDate);

  return now >= startDate && now <= endDate;
};

export const getProductPrice = (product: {
  regularPrice: number;
  salePrice?: number;
  promotionalPeriod?: {
    startDate: string;
    endDate: string;
  } | null;
} | null): number => {
  if (!product) return 0;
  
  const isOnValidPromotion = isPromotionValid(product.promotionalPeriod);
  return isOnValidPromotion && product.salePrice ? product.salePrice : product.regularPrice;
};
