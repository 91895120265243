export const OrderStatusMap = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido',
  awaiting_payment: 'Aguardando Pagamento'
};

export const OrderStatusColorMap = {
  pending: 'bg-yellow-100 text-yellow-800',
  preparation: 'bg-blue-100 text-blue-800',
  awaitingStock: 'bg-orange-100 text-orange-800',
  awaitingPickup: 'bg-purple-100 text-purple-800',
  shipped: 'bg-indigo-100 text-indigo-800',
  delivered: 'bg-green-100 text-green-800',
  cancelled: 'bg-red-100 text-red-800',
  returned: 'bg-red-100 text-red-800',
  awaiting_payment: 'bg-yellow-100 text-yellow-800'
};
