import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Wallet } from '../types';

export const useWallet = (userId: string | undefined) => {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'wallets', userId),
      (doc) => {
        if (doc.exists()) {
          setWallet({ id: doc.id, ...doc.data() } as Wallet);
        }
        setLoading(false);
      },
      (err) => {
        setError('Erro ao carregar wallet');
        console.error(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  return { wallet, loading, error };
};