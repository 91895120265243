import { useEffect, useState } from 'react';
import { collection, query, where, orderBy, getDocs, enableIndexedDbPersistence } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Order } from '../types';

export const useOrders = (userId: string | undefined) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('buyerId', '==', userId)
        );
        
        const snapshot = await getDocs(q);
        const ordersData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => 
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ) as Order[];

        setOrders(ordersData);
      } catch (err: any) {
        console.error('Error fetching orders:', err);
        if (err.code === 'failed-precondition') {
          // Handle offline persistence error
          try {
            // Try without persistence
            const ordersRef = collection(db, 'orders');
            const q = query(
              ordersRef,
              where('buyerId', '==', userId)
            );
            
            const snapshot = await getDocs(q);
            const ordersData = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data()
              }))
              .sort((a, b) => 
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              ) as Order[];

            setOrders(ordersData);
            return;
          } catch (retryErr) {
            console.error('Retry error:', retryErr);
          }
        }
        setError('Erro ao carregar encomendas. Por favor, tente novamente.');
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  return { orders, loading, error };
};