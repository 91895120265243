import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Order } from '../types';
import { useMultibancoPayment } from '../hooks/useMultibancoPayment';
import { useAuth } from '../contexts/AuthContext';
import { addOrderComment, retryMBWayPayment } from '../lib/firebase';
import { Loader, Package, Landmark, Phone, Send } from 'lucide-react';
import MBWayCountdown from '../components/MBWayCountdown';

const OrderStatusMap = {
  pending: 'Pendente',
  preparation: 'Em preparação',
  awaitingStock: 'Aguardar Stock',
  awaitingPickup: 'Aguardar recolha',
  shipped: 'Enviado',
  delivered: 'Entregue',
  cancelled: 'Cancelado',
  returned: 'Devolvido',
  awaiting_payment: 'Aguardando Pagamento'
};

const OrderStatusColorMap = {
  pending: 'bg-yellow-100 text-yellow-800',
  preparation: 'bg-blue-100 text-blue-800',
  awaitingStock: 'bg-orange-100 text-orange-800',
  awaitingPickup: 'bg-purple-100 text-purple-800',
  shipped: 'bg-indigo-100 text-indigo-800',
  delivered: 'bg-green-100 text-green-800',
  cancelled: 'bg-red-100 text-red-800',
  returned: 'bg-red-100 text-red-800',
  awaiting_payment: 'bg-yellow-100 text-yellow-800'
};

const TrackOrderPage = () => {
  const [orderNumber, setOrderNumber] = useState('');
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { payment, loading: paymentLoading } = useMultibancoPayment(order?.id);
  const { user } = useAuth();
  const [newComment, setNewComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [retryingMBWay, setRetryingMBWay] = useState(false);
  const [mbwayError, setMbwayError] = useState<string | null>(null);
  const [lastMBWayRetry, setLastMBWayRetry] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!orderNumber.trim()) return;

    setLoading(true);
    setError(null);
    setOrder(null);

    try {
      const ordersRef = collection(db, 'orders');
      const q = query(ordersRef, where('orderNumber', '==', orderNumber.trim()));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        setError('Encomenda não encontrada. Verifique o número e tente novamente.');
        return;
      }

      const orderData = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() } as Order;
      setOrder(orderData);
    } catch (err) {
      console.error('Error fetching order:', err);
      setError('Erro ao buscar encomenda. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitComment = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newComment.trim() || !order?.id) return;

    setSubmitting(true);
    try {
      await addOrderComment(order.id, {
        text: newComment.trim(),
        createdBy: user.displayName || 'Utilizador',
        createdById: user.uid,
        createdAt: new Date().toISOString(),
        id: Date.now().toString()
      });
      setNewComment('');
      
      // Refresh order data to show new comment
      const orderRef = await getDocs(query(
        collection(db, 'orders'),
        where('orderNumber', '==', order.orderNumber)
      ));
      if (!orderRef.empty) {
        setOrder({ id: orderRef.docs[0].id, ...orderRef.docs[0].data() } as Order);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRetryMBWay = async () => {
    if (!order?.id) return;
    
    setRetryingMBWay(true);
    setMbwayError(null);

    try {
      const result = await retryMBWayPayment(order.id);
      setLastMBWayRetry(new Date().toISOString());
    } catch (err) {
      setMbwayError(err instanceof Error ? err.message : 'Erro ao reenviar pagamento MBWAY');
    } finally {
      setRetryingMBWay(false);
    }
  };

  const isMBWayExpired = (createdAt: string) => {
    const paymentTime = new Date(lastMBWayRetry || createdAt).getTime();
    const now = new Date().getTime();
    return (now - paymentTime) > 6 * 60 * 1000;
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-secondary mb-4">
          Consultar Encomenda
        </h1>
        <p className="text-gray-600">
          Introduza o número da sua encomenda para verificar o estado
        </p>
      </div>

      <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-8">
        <div className="flex gap-4">
          <input
            type="text"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            placeholder="Número da encomenda (ex: PS1234567890)"
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
          <button
            type="submit"
            disabled={loading || !orderNumber.trim()}
            className="bg-primary text-secondary px-6 py-2 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center"
          >
            {loading ? (
              <Loader className="w-5 h-5 animate-spin" />
            ) : (
              'Consultar'
            )}
          </button>
        </div>
      </form>

      {error && (
        <div className="max-w-md mx-auto text-center text-red-500 mb-8">
          {error}
        </div>
      )}

      {order && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-xl font-semibold text-secondary">
                Encomenda #{order.orderNumber}
              </h2>
              <p className="text-gray-500">
                {new Date(order.createdAt).toLocaleDateString('pt-PT', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </p>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm font-medium ${OrderStatusColorMap[order.status] || 'bg-gray-100 text-gray-800'}`}>
              {OrderStatusMap[order.status] || order.status}
            </span>
          </div>

          {/* MBWAY Payment Section */}
          {order.paymentMethod === 'mbway' && order.status === 'awaiting_payment' && (
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              <div className="flex items-center space-x-3 mb-4">
                <Phone className="w-6 h-6 text-primary" />
                <h2 className="text-lg font-semibold text-secondary">
                  Pagamento MBWAY
                </h2>
              </div>

              {isMBWayExpired(order.createdAt) ? (
                <div className="space-y-4">
                  <p className="text-gray-600">
                    O tempo para pagamento MBWAY expirou. Deseja tentar novamente?
                  </p>
                  {mbwayError && (
                    <div className="text-red-500 text-sm">
                      {mbwayError}
                    </div>
                  )}
                  <button
                    onClick={handleRetryMBWay}
                    disabled={retryingMBWay}
                    className="bg-primary text-secondary px-4 py-2 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center"
                  >
                    {retryingMBWay ? (
                      <>
                        <Loader className="w-5 h-5 animate-spin mr-2" />
                        A processar...
                      </>
                    ) : (
                      <>
                        <Phone className="w-5 h-5 mr-2" />
                        Solicitar novo pagamento MBWAY
                      </>
                    )}
                  </button>
                </div>
              ) : (
                <MBWayCountdown 
                  onTimeout={() => {
                    if (order) {
                      order.createdAt = new Date(0).toISOString();
                    }
                  }} 
                />
              )}
            </div>
          )}

          {/* Multibanco Payment Section */}
          {payment && order.paymentMethod === 'multibanco' && order.status === 'awaiting_payment' && (
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              <div className="flex items-center space-x-3 mb-4">
                <Landmark className="w-6 h-6 text-primary" />
                <h2 className="text-lg font-semibold text-secondary">
                  Dados para Pagamento Multibanco
                </h2>
              </div>
              <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                <div>
                  <p className="text-sm text-gray-500">Entidade</p>
                  <p className="text-2xl font-mono font-bold text-secondary">{payment.entity}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Referência</p>
                  <p className="text-2xl font-mono font-bold text-secondary">{payment.reference}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Valor</p>
                  <p className="text-2xl font-mono font-bold text-secondary">€{payment.amount.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Data Limite</p>
                  <p className="text-2xl font-mono font-bold text-red-600">
                    {new Date(payment.expiryDate).toLocaleDateString('pt-PT')}
                  </p>
                </div>
              </div>
              <p className="text-sm text-red-500 mt-4">
                ⚠️ IMPORTANTE: Tem apenas 2 dias de calendário para efetuar o pagamento.
                Após este prazo, a encomenda será automaticamente cancelada.
              </p>
            </div>
          )}

          {/* Order Details */}
          <div className="space-y-6">
            <div>
              <h3 className="font-medium text-secondary mb-4">Produtos</h3>
              <div className="space-y-4">
                {order.items.map((item) => (
                  <div key={item.productId} className="flex items-center space-x-4">
                    {item.mainImage && (
                      <img
                        src={item.mainImage}
                        alt={item.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                    )}
                    <div className="flex-1">
                      <h4 className="font-medium text-secondary">{item.name}</h4>
                      <p className="text-sm text-gray-500">
                        Quantidade: {item.quantity} × €{item.price.toFixed(2)}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium text-secondary">
                        €{(item.price * item.quantity).toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Order Summary */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="space-y-2">
                <div className="flex justify-between text-gray-600">
                  <span>Subtotal</span>
                  <span>€{order.subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-gray-600">
                  <span>Envio</span>
                  <span>€{order.shippingCost.toFixed(2)}</span>
                </div>
                {order.useWallet && (
                  <div className="flex justify-between text-green-600">
                    <span>Saldo Wallet</span>
                    <span>-€{order.walletAmount.toFixed(2)}</span>
                  </div>
                )}
                <div className="border-t pt-2 mt-2">
                  <div className="flex justify-between font-medium">
                    <span>Total</span>
                    <span>€{order.total.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Comments Section */}
            <div className="mt-8">
              <h3 className="font-medium text-secondary mb-4">Comentários</h3>
              <div className="space-y-4 mb-6">
                {order.comments?.map((comment) => (
                  <div key={comment.id || `${comment.createdAt}-${comment.createdBy}`} className="flex space-x-3">
                    <div className="flex-1 bg-gray-50 rounded-lg p-4">
                      <div className="flex justify-between items-start">
                        <span className="font-medium text-secondary">
                          {comment.createdBy}
                        </span>
                        <span className="text-sm text-gray-500">
                          {new Date(comment.createdAt).toLocaleString('pt-PT')}
                        </span>
                      </div>
                      <p className="mt-1 text-gray-700">{comment.text}</p>
                    </div>
                  </div>
                ))}
              </div>

              {user && (
                <form onSubmit={handleSubmitComment} className="flex space-x-2">
                  <input
                    type="text"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Adicionar comentário..."
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary text-gray-900"
                  />
                  <button
                    type="submit"
                    disabled={!newComment.trim() || submitting}
                    className="bg-primary text-secondary px-4 py-2 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50 flex items-center"
                  >
                    <Send className="w-4 h-4 mr-2" />
                    Enviar
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      )}

      {!order && !error && !loading && (
        <div className="text-center text-gray-500">
          <Package className="w-16 h-16 mx-auto mb-4" />
          <p>Introduza o número da sua encomenda para ver os detalhes</p>
        </div>
      )}
    </div>
  );
};

export default TrackOrderPage;