import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useProduct } from '../hooks/useProduct';
import { useCompany } from '../hooks/useCompany';
import { useAuth } from '../hooks/useAuth';
import { useCart } from '../contexts/CartContext';
import { Loader, ShoppingBag } from 'lucide-react';
import { isPromotionValid, getProductPrice } from '../utils/promotions';
import ProductReviews from '../components/ProductReviews';
import SEO from '../components/SEO';

const ProductPage = () => {
  const { id } = useParams();
  const { product, loading, error } = useProduct(id);
  const { company } = useCompany(product?.companyId);
  const { user } = useAuth();
  const { addItem } = useCart();
  const [quantity, setQuantity] = useState(1);
  const [currentImage, setCurrentImage] = useState('');
  const [showZoomedImage, setShowZoomedImage] = useState(false);
  const [imageReady, setImageReady] = useState(false);

  useEffect(() => {
    if (product?.mainImage) {
      setCurrentImage(product.mainImage);
      setImageReady(true);
    }
  }, [product]);

  const handleZoom = () => {
    setShowZoomedImage(!showZoomedImage);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        {error || 'Produto não encontrado'}
      </div>
    );
  }

  const baseUrl = window.location.origin;
  const productUrl = `${baseUrl}/product/${product.id}`;
  const currentPrice = getProductPrice(product);

  return (
    <>
      <SEO
        title={product.name}
        description={product.description}
        image={product.mainImage}
        url={productUrl}
        type="product"
        price={currentPrice.toString()}
        brand={company?.name || 'EM TUA CASA®'}
        availability={product.stockQuantity > 0}
        productId={product.id}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Product Images */}
          <div className="space-y-4">
            <div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden relative">
              <img
                src={currentImage || product.mainImage || 'https://via.placeholder.com/600'}
                alt={product.name}
                className="w-full h-full object-center object-cover"
              />
              <button 
                onClick={handleZoom}
                className="absolute top-2 right-2 p-2 bg-white/80 rounded-full hover:bg-white transition-colors"
              >
                <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
            </div>
            {product.galleryImages && (
              <div className="grid grid-cols-4 gap-4">
                <button
                  onClick={() => setCurrentImage(product.mainImage)}
                  className={`aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden ${
                    currentImage === product.mainImage ? 'ring-2 ring-primary' : ''
                  }`}
                >
                  <img
                    src={product.mainImage}
                    alt={`${product.name} - principal`}
                    className="w-full h-full object-center object-cover"
                  />
                </button>
                {product.galleryImages.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImage(image)}
                    className={`aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden ${
                      currentImage === image ? 'ring-2 ring-primary' : ''
                    }`}
                  >
                    <img
                      src={image}
                      alt={`${product.name} - ${index + 1}`}
                      className="w-full h-full object-center object-cover"
                    />
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Zoom Modal */}
          {showZoomedImage && (
            <div className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4" onClick={handleZoom}>
              <div className="relative max-w-4xl w-full">
                <img
                  src={currentImage || product.mainImage}
                  alt={product.name}
                  className="w-full h-auto"
                />
                <button 
                  className="absolute top-4 right-4 text-white hover:text-gray-300"
                  onClick={handleZoom}
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* Product Info */}
          <div>
            <div className="mb-4">
              <h1 className="text-3xl font-bold text-secondary mb-2">{product.name}</h1>
              <Link
                to={`/seller/${company?.id}`}
                className="text-primary hover:underline"
              >
                Vendido por: {company?.commercialName}
              </Link>
            </div>

            {/* Promotional Banner */}
            {isPromotionValid(product.promotionalPeriod) && product.salePrice && product.salePrice < product.regularPrice && (
              <div className="bg-red-50 border-l-4 border-red-500 p-4 my-6">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="text-red-500 text-xl font-bold">🔥</span>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-red-800 font-medium">Oferta Especial!</h3>
                    <div className="mt-2 text-red-700">
                      <p className="text-sm">
                        Poupe {((product.regularPrice - product.salePrice!) / product.regularPrice * 100).toFixed(0)}% 
                        nesta oferta por tempo limitado!
                      </p>
                      <p className="text-xs mt-1">
                        Promoção válida até {new Date(product.promotionalPeriod!.endDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-baseline space-x-4 mb-6">
              <span className="text-3xl font-bold text-secondary">
                €{getProductPrice(product).toFixed(2)}
              </span>
              {isPromotionValid(product.promotionalPeriod) && product.salePrice && product.salePrice < product.regularPrice && (
                <span className="text-xl text-gray-500 line-through">
                  €{product.regularPrice.toFixed(2)}
                </span>
              )}
            </div>

            {/* Add to Cart */}
            <div className="flex items-center space-x-4 mb-8">
              {product.stockQuantity > 0 ? (
                <>
                  <div className="flex items-center border rounded-md">
                    <button
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      className="px-3 py-2 text-gray-600 hover:text-secondary"
                      disabled={quantity <= 1}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="1"
                      max={product.stockQuantity}
                      value={quantity}
                      onChange={(e) => {
                        const newQuantity = parseInt(e.target.value) || 1;
                        setQuantity(Math.min(Math.max(1, newQuantity), product.stockQuantity));
                      }}
                      className="w-16 text-center border-x py-2"
                    />
                    <button
                      onClick={() => setQuantity(Math.min(quantity + 1, product.stockQuantity))}
                      className="px-3 py-2 text-gray-600 hover:text-secondary"
                      disabled={quantity >= product.stockQuantity}
                    >
                      +
                    </button>
                  </div>
                  <button
                    onClick={() => addItem(product, quantity)}
                    className="flex-1 bg-primary text-secondary py-3 rounded-md hover:bg-primary-dark transition-colors flex items-center justify-center"
                  >
                    <ShoppingBag className="w-5 h-5 mr-2" />
                    Adicionar ao Carrinho
                  </button>
                </>
              ) : (
                <div className="w-full text-center py-3 bg-gray-100 rounded-md text-gray-500">
                  Produto Fora de Stock
                </div>
              )}
            </div>

            {/* Product Details */}
            <div className="prose max-w-none">
              <h3 className="text-xl font-semibold text-secondary mb-4">Descrição</h3>
              <p className="text-gray-600">{product.description}</p>
              
              {product.ean && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">EAN: {product.ean}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        {id && <ProductReviews productId={id} />}
      </div>
    </>
  );
};

export default ProductPage;