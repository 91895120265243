import React, { useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../types';

const KuantoKustaFeed = () => {
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, 'products');
        const snapshot = await getDocs(productsRef);
        const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Product));

        const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<products>
  ${products.map(product => `
    <product>
      <mode>comparison</mode>
      <woocommerce_product_type>simple</woocommerce_product_type>
      <id_product>${product.id}</id_product>
      <product_url><![CDATA[https://emtuacasa.pt/product/${product.id}]]></product_url>
      <designation><![CDATA[${product.title}]]></designation>
      <regular_price>${product.regularPrice?.toFixed(2) || '0.00'}</regular_price>
      <current_price>${(product.salePrice || product.regularPrice)?.toFixed(2) || '0.00'}</current_price>
      <stock>${product.stock || 0}</stock>
      <category><![CDATA[${product.category || ''}]]></category>
      <image_url><![CDATA[${product.images?.[0] || ''}]]></image_url>
      <description><![CDATA[${product.description || ''}]]></description>
      <brand><![CDATA[${product.brand || ''}]]></brand>
      <upc_ean><![CDATA[${product.ean || ''}]]></upc_ean>
      <reference><![CDATA[${product.sku || ''}]]></reference>
      <weight>${(product.weight || 0).toFixed(3)} kg</weight>
      <shipping_cost>0.00</shipping_cost>
    </product>
  `).join('')}
</products>`;

        // Criar o blob e fazer download
        const blob = new Blob([xmlContent], { type: 'application/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'kuantokusta.xml';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error generating XML feed:', error);
      }
    };

    fetchProducts();
  }, []);

  return null;
};

export default KuantoKustaFeed;
